
export const menuItems = [
    {
        path: "/",
        label: "Dashboard",
        icon: "../assets/images/dashboard-icon.svg",
        hasSubMenu: false
    },
    {
        path: "/areas",
        label: "Areas",
        icon: "../assets/images/city-icon.svg",
        hasSubMenu: false,
        access: "system_access"
    },
    {
        path: "/cities",
        label: "Cities",
        icon: "../assets/images/city-icon.svg",
        hasSubMenu: false,
        access: "system_access"
    },
    {
        path: "/countries",
        label: "Countries",
        icon: "../assets/images/country-icon.svg",
        hasSubMenu: false,
        access: "system_access"
    },
    {
        path: "/inclusions",
        label: "Inclusion",
        icon: "../assets/images/country-icon.svg",
        hasSubMenu: false,
        access: "system_access"
    },
    {
        path: "/features",
        label: "Features",
        icon: "../assets/images/features-icon.svg",
        hasSubMenu: true,
        subMenu: [
            {
                path: "/features",
                label: "All Features"
            },
            {
                path: "/feature-categories",
                label: "Feature Categories"
            }
        ],
        access: "system_access"
    },
    {
        path: "/desk-types",
        label: "Desk Types",
        icon: "../assets/images/types-icon.svg",
        hasSubMenu: true,
        subMenu: [
            {
                path: "/desk-types",
                label: "Desk Types"
            },
            {
                path: "/room-types",
                label: "Room Types"
            },
        ],
        access: "system_access"
    },
    {
        path: "/blogs",
        label: "Blogs",
        icon: "../assets/images/blogs-icon.svg",
        hasSubMenu: true,
        subMenu: [
            {
                path: "/blogs",
                label: "All Blogs"
            },
            {
                path: "/blog-categories",
                label: "Categories"
            },
        ],
        access: "blogs_access"
    },
    {
        path: "/admins",
        label: "Admin",
        icon: "../assets/images/admin-icon.svg",
        hasSubMenu: false,
        access: "role",
        role: "admin"
    },
    {
        path: "/admin-businesses",
        label: "Businesses",
        icon: "../assets/images/businesses-icon-cw.svg",
        hasSubMenu: true,
        subMenu: [
            {
                path: "/admin-businesses",
                label: "Businesses By Admin"
            },
            {
                path: "/owner-businesses",
                label: "Businesses By Owner"
            },
            {
                path: "/contract-businesses",
                label: "Businesses By Contract"
            },
        ],
        access: "space_access"
    },
    {
        path: "/spaces",
        label: "Spaces",
        icon: "../assets/images/spaces-icon.svg",
        hasSubMenu: true,
        subMenu: [
            {
                path: "/spaces",
                label: "Spaces By Admin"
            },
            {
                path: "/business-spaces",
                label: "Spaces By Business"
            }
        ],
        access: "space_access"
    },
    {
        path: "/business-users",
        label: "Users",
        icon: "../assets/images/users-icon.svg",
        hasSubMenu: true,
        subMenu: [
            {
                path: "/business-users",
                label: "Business Users"
            },
            {
                path: "/customer-users",
                label: "Customer Users"
            },
        ],
        access: "users_access"
    },
    {
        path: "/user-requested-quotes",
        label: "Requested Quotes",
        icon: "../assets/images/requested-quotes-icon.svg",
        hasSubMenu: false,
        subMenu: [
            {
                path: "/user-requested-quotes",
                label: "User Requested Quotes"
            },
            {
                path: "/guest-requested-quotes",
                label: "Guest Requested Quotes"
            }
        ],
        access: "users_access"
    },
    {
        path: "/careers",
        label: "Careers",
        icon: "../assets/images/careers-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/claim-spaces",
        label: "Claim Spaces",
        icon: "../assets/images/claim-spaces-icon.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/report-spaces",
        label: "Report Spaces",
        icon: "../assets/images/report-spaces-icon.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/landlord-property",
        label: "Landlord Property",
        icon: "../assets/images/landlord-property-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/contact-us-list",
        label: "Contact Us",
        icon: "../assets/images/contact-us-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/enterprise-solutions",
        label: "Enterprise Solutions",
        icon: "../assets/images/enterprise-solution-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/newsletter-emails",
        label: "Newsletter",
        icon: "../assets/images/newsletter-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/meta-data",
        label: "Meta Data",
        icon: "../assets/images/newsletter-icon-cw.svg",
        hasSubMenu: false,
        access: "blogs_access"
    },
    {
        path: "/abandoned-spaces",
        label: "Abandoned Spaces",
        icon: "../assets/images/newsletter-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    // {
    //     path: "/claim-invitation",
    //     label: "Claim Invitation",
    //     icon: "../assets/images/newsletter-icon-cw.svg",
    //     hasSubMenu: false,
    //     access: "users_access"
    // },
    {
        path: "/booked-tour-list",
        label: "Booked Tours",
        icon: "../assets/images/newsletter-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
    {
        path: "/contract-templates",
        label: "Contract Templates",
        icon: "../assets/images/newsletter-icon-cw.svg",
        hasSubMenu: false,
        access: "users_access"
    },
];

// export const getPagePermission = (pageTitle, permissions) => {
//     const permissionKey = pageTitle
//         .toLowerCase()
//         .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
//         .split(" ") // Split into words
//         .map((word, index) =>
//             index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize after the first word
//         )
//         .join(""); // Join words together

//     return permissions[permissionKey] || null; // Return the permission object or null if not found
// };

export const getPagePermission = (pageTitle, profile) => {
    // Check if the user is an admin
    if (profile?.role === "admin") {
        // If the user is an admin, return full access
        return { read: true, write: true, delete: true };
    }

    // Clean and format the page title to create the permission key
    const permissionKey = pageTitle
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .split(" ") // Split into words
        .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize after the first word
        )
        .join(""); // Join words together

    const permissions = profile.permissions

    // Return permission object based on the formatted key or null if not found
    return permissions[permissionKey] || { read: false, write: false, delete: false }; // Default to no access if no permission is found
};
