import React, { useState } from 'react'
import FloatingTextArea from '../../components/elements/FloatingTextArea'
import ContentSection from '../../components/elements/ContentSection';
import Breadcrumb from '../../components/elements/Breadcrumb';
import BoxSection from '../../components/elements/BoxSection';
import Title from '../../components/elements/Title';
import Spinner from '../../components/Spinner';
import Button from '../../components/elements/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, sendEmailList } from '../../store/apiSlice';
import { getPagePermission } from '../../utils/Helper';

const SendEmail = () => {
  const dispatch = useDispatch()
  const [emails, setEmails] = useState([]);
  const [load, setload] = useState(false);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      setload(true)
      const payload = { emails: emails.filter((email) => email.trim() !== "") }
      console.log(payload, "payload");
      const response = await dispatch(sendEmailList(payload)).unwrap();
      setload(false)
      setEmails([])
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let pasteText = e.clipboardData.getData("text");

    // Format pasted text: Convert spaces, semicolons, or new lines into commas
    let formattedEmails = pasteText
      .replace(/[\s;\n]+/g, ",") // Normalize delimiters
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email); // Remove empty values

    setEmails((prev) => [...new Set([...prev, ...formattedEmails])]); // Avoid duplicates
  };

  return (
    <>
      {load && <Spinner />}
      <ContentSection title="Send Emails">
        <Breadcrumb items={[{ label: 'Menu', }, { label: "Send Emails", active: true },]} />
        <Title bottomSpace={2}>Send Emails</Title>
        <BoxSection bottomSpace={4}>
          <FloatingTextArea
            value={emails.join(", ")}// Show emails as a comma-separated string
            onChange={(e) => {
              const emailArray = e.target.value
                .split(/[\s,]+/) // Split by commas, spaces, or newlines
              // .filter((email) => email.trim() !== ""); // Remove empty entries
              setEmails(emailArray);
            }}
            // onChange={handleChange}
            onPaste={handlePaste}
            placeholder="Enter multiple emails separated by commas or spaces"
          />
        </BoxSection>
        <div className="d-flex justify-content-center gap-2">
          <Button onClick={HandleSubmit} size="small">Send</Button>
        </div>
      </ContentSection>
    </>
  )
}

export default SendEmail