import { useDispatch, useSelector } from "react-redux";
import { getCareers, getProfile } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import { Download, Eye, Trash2 } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import { getPagePermission } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";


const Careers = () => {
  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [requestedCareer, setrequestedCareer] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const careerDelete = async (id) => {
    try {
      // await dispatch(deleteCareer(id)).unwrap();
      setIsOpen(false);
      setrequestedCareer((prevCareers) => prevCareers?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const CareerRequest = async () => {
    try {
      setload(true);
      setrequestedCareer(null);
      const response = await dispatch(getCareers()).unwrap();
      setrequestedCareer(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    CareerRequest();
  }, []);

  const downloadAttachment = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
    }
  };


  const navigate = useNavigate();
  const pageTitle = "Careers";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Career Detail" closeModal={closeModal}>
            <TextDetail label="First Name" data={userDetail?.firstName} />
            <TextDetail label="Last Name" data={userDetail?.lastName} />
            <TextDetail label="Email" data={userDetail?.email} />
            <TextDetail label="Phone" data={userDetail?.phoneNumber} />
            <TextDetail label="Position" data={userDetail?.position} />
            <TextDetail label="City" data={userDetail?.city?.name} />
            <TextDetail label="Country" data={userDetail?.country?.name} />
            <TextDetail label="Uploaded Resume" data={userDetail?.resume ? "Uploaded" : "Not Uploaded"} />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => careerDelete(id)} label="Career" />
        ) : null}
      </CustomModal>

      <ContentSection title="Careers">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Careers', active: true },]} />
        <Title bottomSpace={2}>Careers</Title>
        <BoxSection>
          <p className="font-size-20 pink-color">Careers : {requestedCareer?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Position</th>
                <th scope="col">City, Country</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : requestedCareer?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.firstName} {item?.lastName}</td>
                  <td>{item?.email}</td>
                  <td>{item?.phoneNumber}</td>
                  <td>{item?.position}</td>
                  <td>{item?.city?.name}, {item?.country?.name}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {item?.resume && <div onClick={() => downloadAttachment(`https://res.cloudinary.com/dayfv4et9/raw/upload/resume/${item?.resume}`, item?.resume)} className={`cw-action-icons l-blue-bg`}><Download width="18" /></div>}
                      <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>
                      {permission?.delete && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Careers