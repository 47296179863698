import React, { useEffect, useState } from 'react'
import ContentSection from '../../components/elements/ContentSection';
import Breadcrumb from '../../components/elements/Breadcrumb';
import { Pencil, Search, Trash2 } from 'lucide-react';
import Table from '../../components/elements/Table';
import BoxSection from '../../components/elements/BoxSection';
import Button from '../../components/elements/Button';
import Title from '../../components/elements/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DropdownSearch from '../../components/elements/DropdownSearch';
import { addCityArea, deleteCityArea, editCityArea, getAllCountries, getCityArea, getCityArea2, getCountryCity, getProfile } from '../../store/apiSlice';
import Pagination from '../../components/elements/Pagination';
import CustomModal from '../../components/elements/Modal';
import ModalContent from '../../components/elements/ModalContent';
import FloatingInput from '../../components/elements/FloatingInput';
import DeleteModal from '../../components/DeleteModal';
import { getPagePermission } from '../../utils/Helper';

const Areas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [countries, setcountries] = useState([]);
    const [cities, setcities] = useState([]);
    const [areas, setareas] = useState([]);
    const [country, setcountry] = useState("");
    const [city, setcity] = useState("");
    const [searchValue, setSearchValue] = useState('');
    const [searchValue1, setSearchValue1] = useState('');
    const [search, setsearch] = useState('');
    const [search2, setsearch2] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [load, setload] = useState(false);
    const [totalCount, settotalCount] = useState('');
    const [id, setId] = useState("");
    const [name, setname] = useState('');
    const [slug, setslug] = useState('');

    const viewModal = async (item, type) => {
        if (type === "add") {
            setname("");
            setslug("");
            setId("");
        } else if (type === "edit") {
            setslug(item?.slug);
            setname(item?.name);
            setId(item?._id);
        } else if (type == "delete") {
            setId(item);
        }
        setModalType(type);
        setIsOpen(true);
    };


    function closeModal() {
        setIsOpen(false);
        setId("")
        setname("")
    }

    const areaDelete = async (id) => {
        try {
            await dispatch(deleteCityArea(id)).unwrap();
            setIsOpen(false);
            setareas((prev) => prev?.filter(item => item?._id !== id));
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const Countries = async () => {
        try {
            setcountries(null);
            const response = await dispatch(getAllCountries()).unwrap();
            setcountries(response?.data);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        Countries();
    }, []);

    const Cities = async (countryId) => {
        try {
            setcities(null);
            const response = await dispatch(getCountryCity(countryId)).unwrap();
            setcities(response?.data);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

    const Areas = async (cityId) => {
        try {
            setareas(null);
            const response = await dispatch(getCityArea2({ cityId, page: currentPage, search: search2 })).unwrap();
            setareas(response?.data);
            settotalCount(response?.count)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        if (country) {
            Cities(country?.id);
        }
    }, [country]);

    useEffect(() => {
        if (city) {
            Areas(city);
        }
    }, [city, search2, currentPage]);


    const handleCountrySelect = (country) => {
        setcountry(country);
        setSearchValue(country?.name);
        console.log('Selected Country:', country);
    };

    const handleCitySelect = (city) => {
        setcity(city?._id);
        setSearchValue1(city?.name);
        console.log('Selected city:', city);
    };

    const handleKeyDown2 = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setsearch2(search);
        }
    };

    const handlePageChange = (page) => {
        navigate(`${location.pathname}?page=${page}`);
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (location.search) {
            const queryParams = new URLSearchParams(location.search);
            const pageParam = queryParams.get('page');
            const parsedPage = parseInt(pageParam, 10) || 1;
            setCurrentPage(parsedPage);
        }
    }, [location.search]);


    const addandEditArea = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                id: id,
                name: name,
                slug: slug,
                cityId: city,
            }
            if (id) {
                await dispatch(editCityArea(payload)).unwrap();
            } else {
                await dispatch(addCityArea(payload)).unwrap();
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const profile = useSelector(getProfile)
    const pageTitle = "Areas";
    const permission = getPagePermission(pageTitle, profile);
    console.log("Permission:", permission);

    useEffect(() => {
        if (permission && permission.read === false) {
            navigate("/");
        }
    }, [permission, navigate]);

    return (
        <>
            <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
                {(modalType === "add" || modalType === "edit") ? (
                    <ModalContent title={modalType === "add" ? "Add Area" : "Edit Area"} closeModal={closeModal} onClick={addandEditArea} buttonText={modalType === "add" ? "Submit" : "Update"}>
                        <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Area Name" bottomSpace={3} />
                        <FloatingInput value={slug} onChange={(e) => setslug(e.target.value)} placeholder="Area Slug" bottomSpace={3} />
                    </ModalContent>
                ) : modalType == "delete" ? (
                    <DeleteModal closeModal={closeModal} onClick={() => areaDelete(id)} label="Area" />
                ) : null}
            </CustomModal>
            <ContentSection title="Areas">
                <Breadcrumb items={[{ label: 'Menu', }, { label: 'Areas', active: true },]} />
                <div className="row gy-3 mb-4 align-items-center mb-2">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <Title>Areas</Title>
                    </div>
                    <div className={`${country ? "col-md-3" : "col-md-6"} order-md-2 order-3`}>
                        <DropdownSearch items={countries} label="Country" placeholder="Enter country" onItemSelect={handleCountrySelect} bottomSpace={3} valueName={searchValue} />
                    </div>
                    {country && (
                        <div className="col-md-3 order-md-2 order-3">
                            <DropdownSearch items={cities} label="City" placeholder="Enter city" onItemSelect={handleCitySelect} bottomSpace={3} valueName={searchValue1} />
                        </div>
                    )}
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        {permission?.write && <Button disabled={!city} onClick={() => viewModal(null, "add")}>Add Area</Button>}
                    </div>
                </div>
                <BoxSection>
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <p className="font-size-20 pink-color mb-0">Areas : {totalCount}</p>
                        </div>
                        <div className="col-md-6">
                            <div class="flex-grow-1 sm-search-chats">
                                <Search width="18" height="18" className="sm-search-chats-icon" stroke="var(--color3)" />
                                <input type="text" class="form-control sm-search-chats-field rounded-0" style={{ borderColor: "var(--color3) !important" }} placeholder="Search..." value={search}
                                    onChange={(e) => setsearch(e.target.value)} onKeyDown={handleKeyDown2} />
                            </div>
                        </div>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name</th>
                                <th style={{ width: '100px' }} scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : areas?.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>
                                        <div className="d-flex align-items-center gap-2">
                                            {permission?.write && <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>}
                                            {permission?.delete && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                                        </div>
                                    </td>
                                </tr>))}
                        </tbody>
                    </Table>
                    {totalCount > 20 &&
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    }
                </BoxSection>
            </ContentSection>
        </>
    )
}

export default Areas