import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAbandonedSpaces, getProfile } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import Pagination from "../../components/elements/Pagination";
import moment from "moment";
import { Eye } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import Title from "../../components/elements/Title";
import { getPagePermission } from "../../utils/Helper";


const AbandonedSpaces = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [abandonedSpaces, setabandonedSpaces] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);

  const [totalCount, settotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setUserDetail("")
  }

  const AbandonedSpacesList = async () => {
    try {
      setload(true);
      setabandonedSpaces(null);
      const response = await dispatch(getAbandonedSpaces({ page: currentPage, search: "" })).unwrap();
      setabandonedSpaces(response?.data);
      settotalCount(response?.totalCount);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    AbandonedSpacesList();
  }, [currentPage]);

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const profile = useSelector(getProfile)
  const pageTitle = "Abandoned Spaces";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Abandoned Space Detail" closeModal={closeModal}>
            <TextDetail label="Business Name" data={userDetail?.businessName} />
            <TextDetail label="Space Name" data={userDetail?.name} />
            <TextDetail label="Email" data={userDetail?.userEmail} />
            <TextDetail label="Space Manager Name" data={userDetail?.spaceManagerName} />
            <TextDetail label="Space Phone" data={userDetail?.spacePhone} />
            <TextDetail label="Space Email" data={userDetail?.spaceEmail} />
            <TextDetail label="Website" data={userDetail?.website} />
            <TextDetail label="Address" data={userDetail?.location?.address} />
            <TextDetail label="Location" data={`${userDetail?.cities?.name}, ${userDetail?.countries?.name}`} />
            <TextDetail label="Days" data={userDetail?.days?.join(', ')} />
            <TextDetail label="Time" data={userDetail?.spaceStartTime === "00:00" && userDetail?.spaceEndTime === "23:59" ? "24 Hours" : `${userDetail?.spaceStartTime} to ${userDetail?.spaceEndTime}`} />
            {userDetail?.social?.faceBook && <TextDetail label="Facebook" data={userDetail?.social?.faceBook} />}
            {userDetail?.social?.instagram && <TextDetail label="Instagram" data={userDetail?.social?.instagram} />}
            {userDetail?.social?.linkedIn && <TextDetail label="LinkedIn" data={userDetail?.social?.linkedIn} />}
            {userDetail?.social?.tiktok && <TextDetail label="Tiktok" data={userDetail?.social?.tiktok} />}
            {userDetail?.social?.twitter && <TextDetail label="X/Twitter" data={userDetail?.social?.twitter} />}
            {userDetail?.social?.youtube && <TextDetail label="Youtube" data={userDetail?.social?.youtube} />}
            <TextDetail label="Types" data={userDetail?.types?.map((item) => item?.name).join(', ')} />
            <TextDetail label="Features" data={userDetail?.features?.map((item) => item?.name).join(', ')} />
          </ModalContent>
        ) : null}
      </CustomModal>

      <ContentSection title="Abandoned Spaces">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Abandoned Spaces', active: true },]} />
        <Title bottomSpace={2}>Abandoned Spaces</Title>
        <BoxSection>
          <p className="font-size-20 pink-color">Abandoned Spaces : {totalCount}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Business Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Location</th>
                <th scope="col">Error</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : abandonedSpaces?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.businessName}</td>
                  <td>{item?.userEmail}</td>
                  <td>{item?.spacePhone}</td>
                  <td>{item?.cities?.name}, {item?.countries?.name}</td>
                  <td>{item?.errorMessage}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
          <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default AbandonedSpaces