import React, { useState, useContext } from 'react'
import { context } from '../context/context';
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfile } from "../store/apiSlice"
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';
import { menuItems } from '../utils/Helper';


const Sidebar = () => {
    const profile = useSelector(getProfile)
    const location = useLocation();

    // const { toggleButton, SetToggleButton } = useContext(context);
    const [toggleButton, SetToggleButton ] = useState();
    const [toggleMenus, setToggleMenus] = useState({});

    const handleToggle = (index) => {
        setToggleMenus(prevState => ({
            ...prevState,
            [index]: !prevState[index] // Toggle the state of the clicked menu item
        }));
    };

    // const hasAccess = (accessKey) => {
    //     return profile && profile[accessKey] && profile[accessKey].length > 0;
    // };

    const hasAccess = (accessKey) => {
        return profile?.permissions?.[accessKey]?.read === true;
    };
    

    return (
        <>
            <div className={toggleButton ? "sidebar close" : "sidebar"}>
                <div class="logo-details">
                    <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color2)' strokeWidth={1} />
                </div>
                <ul class="nav-links">
                    {/* {menuItems.map((item, index) => {
                        if (item.access && !hasAccess(item.access)) {
                            return null;
                        }

                        if (item.role && profile.role !== item.role) {
                            return null;
                        }

                        return (
                            <li key={index} className={`${location.pathname === item.path ? "active" : ""}  showMenu`}>
                                <div className="icon-link" onClick={() => item.hasSubMenu && handleToggle(index)}>
                                    <Link to={item.path}>
                                        <img src={item.icon} alt={item.label} />
                                        <span className="link_name">{item.label}</span>
                                    </Link>
                                    {item.hasSubMenu && (
                                        <span className="cursor-pointer">
                                            {toggleMenus[index] ? <ChevronUp /> : <ChevronDown />}
                                        </span>
                                    )}
                                </div>
                                {item.hasSubMenu && toggleMenus[index] && (
                                    <ul className="sub-menu">
                                        {item.subMenu.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <Link className={`${location.pathname === subItem.path ? "active" : ""}`} to={subItem.path}>
                                                    {subItem.label}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        );
                    })} */}
                    {menuItems.map((item, index) => {
                        const permissionKey = item.label
                            .toLowerCase()
                            .replace(/[^a-zA-Z0-9\s]/g, "") // Clean the label to match the key format
                            .split(" ")
                            .map((word, index) =>
                                index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join("");

                        // if (item.label === "Dashboard" || hasAccess(permissionKey)) {
                        if (profile?.role === "admin" || item.label === "Dashboard" || hasAccess(permissionKey)) {
                            // If the user doesn't have access and it's not the Dashboard, hide the item
                            if (item.role && profile?.role !== item.role) {
                                return null; // Hide menu item if user's role doesn't match
                            }

                            return (
                                <li key={index} className={`${location.pathname === item.path ? "active" : ""} showMenu`}>
                                    <div className="icon-link" onClick={() => item.hasSubMenu && handleToggle(index)}>
                                        <Link to={item.path}>
                                            <img src={item.icon} alt={item.label} />
                                            <span className="link_name">{item.label}</span>
                                        </Link>
                                        {item.hasSubMenu && (
                                            <span className="cursor-pointer">
                                                {toggleMenus[index] ? <ChevronUp /> : <ChevronDown />}
                                            </span>
                                        )}
                                    </div>
                                    {item.hasSubMenu && toggleMenus[index] && (
                                        <ul className="sub-menu">
                                            {item.subMenu.map((subItem, subIndex) => {
                                                if (subItem.access && !hasAccess(subItem.access)) {
                                                    return null; // Hide sub-menu item if no read access
                                                }

                                                return (
                                                    <li key={subIndex}>
                                                        <Link
                                                            className={`${location.pathname === subItem.path ? "active" : ""}`}
                                                            to={subItem.path}
                                                        >
                                                            {subItem.label}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        }

                        return null; // Hide the menu item if the user doesn't have access and it's not the Dashboard
                    })}
                </ul>
            </div>
        </>

    )
}

export default Sidebar
