import React, { useEffect, useState } from 'react'
import ContentSection from '../../components/elements/ContentSection'
import { useDispatch, useSelector } from 'react-redux';
import BoxSection from '../../components/elements/BoxSection';
import Table from '../../components/elements/Table';
import Title from '../../components/elements/Title';
import Breadcrumb from '../../components/elements/Breadcrumb';
import { getBusinessDetail, getProfile } from '../../store/apiSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomModal from '../../components/elements/Modal';
import ModalContent from '../../components/elements/ModalContent';
import TextDetail from '../../components/elements/TextDetail';
import { Eye } from 'lucide-react';
import moment from 'moment';
import { getPagePermission } from '../../utils/Helper';

const BusinessDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const urlParts = window.location.pathname.split('/');
    const businessId = urlParts[urlParts.length - 1];

    const [load, setload] = useState(false);
    const [detail, setdetail] = useState();
    const [requestedQuotes, setrequestedQuotes] = useState([]);

    const BusinessDetails = async () => {
        try {
            setload(true)
            const response = await dispatch(getBusinessDetail(businessId)).unwrap();
            setdetail(response?.business)
            setrequestedQuotes(response?.data)
            setload(false)
        } catch (rejectedValueOrSerializedError) {
            setload(false)
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        if (businessId) {
            window.scrollTo({ top: 0, left: 0 })
            BusinessDetails();
        }
    }, [businessId]);

    useEffect(() => {
        if (detail) {
            document.title = detail?.name
        }
    }, [detail, location.pathname]);

    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);

    function viewModal(item) {
        setUserDetail(item);
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
        setUserDetail("")
    }

    const profile = useSelector(getProfile)
    const navigate = useNavigate();
    const pageTitle = "Businesses";
    const permission = getPagePermission(pageTitle, profile);
    console.log("Permission:", permission);
  
    useEffect(() => {
      if (permission && permission.read === false) {
        navigate("/");
      }
    }, [permission, navigate]);


    return (
        <>
            <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal} size='big'>
                <ModalContent title="Client Detail" closeModal={closeModal}>
                    <div className="d-flex justify-content-between align-items-center mb-3"><TextDetail bottomSpace={0} label="Name" data={userDetail?.name} /></div>
                    <div className="d-flex justify-content-between align-items-center mb-3"><TextDetail bottomSpace={0} label="Email" data={userDetail?.email} /></div>
                    <div className="d-flex justify-content-between align-items-center mb-3"><TextDetail bottomSpace={0} label="Phone" data={userDetail?.phone} /></div>
                    <div className="d-flex justify-content-between align-items-center mb-3"><TextDetail bottomSpace={0} label="Occupation" data={userDetail?.occupation} /></div>
                    <div className="d-flex justify-content-between align-items-center mb-3"><TextDetail bottomSpace={0} label="Company Name" data={userDetail?.companyName} /></div>

                    <p className="font-size-24 fw-bold mb-2">Requested Quotes</p>
                    <div className="row gy-3 gx-3">
                        {userDetail?.requestaquotes?.map((item, i) => (
                            <div className="col-md-6" key={i}>
                                <div className="border p-2" >
                                    <div className="d-flex justify-content-between align-items-center mb-2"><TextDetail bottomSpace={0} label="Duration" data={item?.duration} /></div>
                                    <div className="d-flex justify-content-between align-items-center mb-2"><TextDetail bottomSpace={0} label="No Of People" data={item?.noOfPeople} /></div>
                                    <div className="d-flex justify-content-between align-items-center mb-2"><TextDetail bottomSpace={0} label="Solution Type" data={item?.roomDeskType?.name} /></div>
                                    <div className="d-flex justify-content-between align-items-center mb-2"><TextDetail bottomSpace={0} label="Space Name" data={item?.space?.name} /></div>
                                    <div className="d-flex justify-content-between align-items-center mb-2"><TextDetail bottomSpace={0} label="Location" data={`${item?.city?.name}, ${item?.country?.name}`} /></div>
                                    <div className="d-flex justify-content-between align-items-center mb-2"><TextDetail bottomSpace={0} label="Accepted Offer" data={`${(item?.acceptedOffer?.amount || 0).toFixed(1).toLocaleString("en-US")} ${detail?.currency} (${item?.acceptedOffer?.type})`} /></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalContent>
            </CustomModal>
            <ContentSection title="Business Details">
                <Breadcrumb items={[{ label: 'Menu', }, { label: "Businesses", link: "/" }, { label: 'Business Details', active: true },]} />
                <Title bottomSpace={2}>{detail?.name}</Title>
                {/* <div className="row justify-content-between mb-3">
                    <div className="col-md-5 d-flex gap-2 align-items-center">
                        <div><MapPinned size={40} stroke='var(--color3)' /></div>
                        <div>
                            <p className="fw-semibold font-size-16 mb-0">Address:</p>
                            <p className="font-size-16 mb-0">{detail?.location?.address}</p>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex gap-2 align-items-center">
                        <div><MapPinned size={40} stroke='var(--color3)' /></div>
                        <div>
                            <p className="fw-semibold font-size-16 mb-0">Phone:</p>
                            <p className="font-size-16 mb-0">{detail?.spacePhone}</p>
                        </div>
                    </div>
                    <div className="col-md-2 d-flex gap-2 align-items-center">
                        <div><MapPinned size={40} stroke='var(--color3)' /></div>
                        <div>
                            <p className="fw-semibold font-size-16 mb-0">Email:</p>
                            <p className="font-size-16 mb-0">{detail?.spaceEmail}</p>
                        </div>
                    </div>
                    <div className="col-md-2 d-flex gap-2 align-items-center">
                        <div><MapPinned size={40} stroke='var(--color3)' /></div>
                        <div>
                            <p className="fw-semibold font-size-16 mb-0">Whatsapp:</p>
                            <p className="font-size-16 mb-0">{detail?.spaceMobile}</p>
                        </div>
                    </div>
                </div> */}

                <BoxSection>
                    <p className="font-size-20 pink-color">Clients: {requestedQuotes?.length}</p>
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Total Commission</th>
                                <th scope="col">Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestedQuotes?.map((item, i) => (
                                <tr key={i}>
                                    <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                                    <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{item?.email}</td>
                                    <td style={{ verticalAlign: "middle" }}>+{item?.phone}</td>
                                    <td style={{ verticalAlign: "middle" }}>{(item?.totalCommission || 0).toFixed(2).toLocaleString("en-US")} {detail?.currency}</td>
                                    <td style={{ verticalAlign: "middle" }}>{moment(item?.updatedAt).format("DD-MM-YYYY")}</td>
                                    <td style={{ verticalAlign: "middle" }}>
                                        <div>
                                            <div onClick={() => viewModal(item)} className="cw-action-icons"><Eye width="18" /></div>
                                        </div>
                                    </td>
                                </tr>))}
                        </tbody>
                    </Table>
                </BoxSection>
            </ContentSection>
        </>
    )
}

export default BusinessDetail