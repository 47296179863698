import React, { useEffect, useState } from 'react'
import { addMetaData, editMetaData, getmetaDataList, getProfile } from '../../store/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment"
import Modal from "react-modal";
import { ExternalLink, Eye, Pencil, Trash2 } from "lucide-react";
import { Link, useNavigate } from 'react-router-dom';
import CustomModal from '../../components/elements/Modal';
import ModalContent from '../../components/elements/ModalContent';
import TextDetail from '../../components/elements/TextDetail';
import DeleteModal from '../../components/DeleteModal';
import FloatingInput from '../../components/elements/FloatingInput';
import FloatingTextArea from '../../components/elements/FloatingTextArea';
import Button from '../../components/elements/Button';
import ContentSection from '../../components/elements/ContentSection';
import Breadcrumb from '../../components/elements/Breadcrumb';
import Title from '../../components/elements/Title';
import BoxSection from '../../components/elements/BoxSection';
import Table from '../../components/elements/Table';
import HtmlTextEditor from '../../components/elements/HtmlTextEditor';
import { getPagePermission } from '../../utils/Helper';
const customStyles = {
  content: {
    display: "block"
  },
};
Modal.setAppElement("#root");

const MetaData = () => {
  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [metaDataList, setmetaDataList] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [keywords, setkeywords] = useState("");
  const [url, seturl] = useState("");
  const [imageName, setimageName] = useState("");
  const [content, setcontent] = useState("");


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "add") {
      settitle("")
      setdescription("")
      setkeywords("")
      setimageName("")
      seturl("")
      setcontent("")
    } else if (type == "edit") {
      setUserDetail(item);
      setId(item?._id);
      settitle(item?.title)
      setdescription(item?.description)
      setkeywords(item?.keywords)
      setimageName(item?.metaImage)
      seturl(item?.url)
      setcontent(item?.content || '')
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const metaDatadelete = async (id) => {
    try {
      // await dispatch(deleteMetaData(id)).unwrap();
      setIsOpen(false);
      setmetaDataList((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const MetaDataList = async () => {
    try {
      setload(true);
      setmetaDataList(null);
      const response = await dispatch(getmetaDataList()).unwrap();
      setmetaDataList(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    MetaDataList();
  }, []);

  const addandEditMetaData = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("title", title);
      data.append("url", url);
      data.append("metaImage", imageName);
      data.append("description", description);
      data.append("keywords", keywords);
      if (id) {
        await dispatch(editMetaData(data)).unwrap();
      } else {
        await dispatch(addMetaData(data)).unwrap();
      }
      setIsOpen(false);
      MetaDataList();
      settitle("")
      setdescription("")
      setkeywords("")
      setimageName("")
      seturl("")
      setcontent("")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleContent = (html) => {
    setcontent(html);
  };


  const navigate = useNavigate();
  const pageTitle = "Meta Data";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Customer Detail" closeModal={closeModal}>
            <TextDetail label="URL" data={userDetail?.url} />
            <TextDetail label="Title" data={userDetail?.title} />
            <TextDetail label="Description" data={userDetail?.description} />
            <TextDetail label="Keywords" data={userDetail?.keywords} />
          </ModalContent>
        ) : (modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Meta Data`} closeModal={closeModal} onClick={addandEditMetaData} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={url} onChange={(e) => seturl(e.target.value)} placeholder="URL" bottomSpace={3} />
            <FloatingInput value={title} onChange={(e) => settitle(e.target.value)} placeholder="Title" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Meta OG Image" bottomSpace={3} />
            <FloatingTextArea value={description} onChange={(e) => setdescription(e.target.value)} placeholder="Meta Description" bottomSpace={3} />
            <FloatingTextArea value={keywords} onChange={(e) => setkeywords(e.target.value)} placeholder="Meta Keywords" bottomSpace={3} />
            <HtmlTextEditor value={content} onChange={handleContent} label="Content" />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => metaDatadelete(id)} label="Meta Data" />
        ) : null}
      </CustomModal>


      <ContentSection title="Meta Data List">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Meta Data List', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Meta Data List</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">

          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          {permission?.write && <Button onClick={() => viewModal(null, "add")}>Add Meta Data</Button>}
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Meta Data List : {metaDataList?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">URL</th>
                <th scope="col">Title</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col" style={{ width: '100px' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : metaDataList?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.url}</td>
                  <td>{item?.title}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <Link target="blank" to={item?.url === "/home" ? `https://www.thecoworkingspaces.com` : `https://www.thecoworkingspaces.com${item?.url}`} className={`cw-action-icons l-blue-bg`}><ExternalLink width="18" /></Link>
                      <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>
                      {permission?.write && <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>}
                      {permission?.delete && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default MetaData