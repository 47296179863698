import { useDispatch, useSelector } from "react-redux";
import { addContractBusiness, aproveBusiness, changeBusinessName, getBusinessCountries, getBusinessCountrySpaces, getBusinessess, getProfile, InviteBusinessOwner, ResendInviteBusinessOwner, verifiedBusiness } from "../../store/apiSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { Building2, Download, Eye, ReceiptText, ReplaceAll } from "lucide-react";
import Pagination from "../../components/elements/Pagination";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import FloatingInput from "../../components/elements/FloatingInput";
import ModalContent from "../../components/elements/ModalContent";
import CustomModal from "../../components/elements/Modal";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import { getPagePermission } from "../../utils/Helper";

const Buisnesses = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [buisnesses, setbuisnesses] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [isAdmin, setisAdmin] = useState(null);
  const [search, setsearch] = useState("");
  const [searchEmail, setsearchEmail] = useState("");
  const [totalCount, settotalCount] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [businessId, setbusinessId] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [countryId, setcountryId] = useState("");
  const [currency, setcurrency] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [percentage, setpercentage] = useState("");
  const [countries, setcountries] = useState(null);
  const [countrySpaces, setcountrySpaces] = useState(null);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type === "userDetail") {
      setId(item?._id);
    } else if (type === "delete") {
      setId(item);
    } else if (type === "changeBusiness") {
      setUserDetail(item);
      setbusinessId(item?._id);
      setbusinessName("")
      setcountryId("")
      setcurrency("")
      setcountrySpaces(null)
    } else if (type === "contract") {
      setUserDetail(item);
      setbusinessId(item?._id);
      setstartDate(item?.contractStart || "")
      setendDate(item?.contractEnd || "")
      setpercentage(item?.contractPercentage || "")
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
    setstartDate("")
    setendDate("")
    setpercentage("")
  }

  const AproveBusiness = async (id) => {
    try {
      const spaceIndex = buisnesses.findIndex(space => space?._id === id);
      if (spaceIndex !== -1) {
        const updatedSpaces = [...buisnesses];
        updatedSpaces[spaceIndex].isApproved = updatedSpaces[spaceIndex].isApproved === 1 ? 0 : 1;
        setbuisnesses(updatedSpaces);
      }
      await dispatch(aproveBusiness(id)).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const VerifiedBusiness = async (id) => {
    try {
      const spaceIndex = buisnesses.findIndex(space => space?._id === id);
      if (spaceIndex !== -1) {
        const updatedSpaces = [...buisnesses];
        updatedSpaces[spaceIndex].isVerify = updatedSpaces[spaceIndex].isVerify === 1 ? 0 : 1;
        setbuisnesses(updatedSpaces);
      }
      await dispatch(verifiedBusiness(id)).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const BusinessCountries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getBusinessCountries(businessId)).unwrap();
      setcountries(response?.data?.[0]?.countries);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (businessId) {
      BusinessCountries();
    }
  }, [businessId]);

  const BusinessCountrySpaces = async () => {
    try {
      setcountrySpaces(null);
      const response = await dispatch(getBusinessCountrySpaces({ countryId: countryId, businessId: businessId })).unwrap();
      setcountrySpaces(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (countryId) {
      BusinessCountrySpaces();
      const findCountry = countries?.find(item => item?._id === countryId);
      setcurrency(findCountry?.currency)
      const newName = `${userDetail?.name} - ${findCountry?.name}`
      setbusinessName(newName)
    }
  }, [countryId]);

  // const addUser = async (id) => {
  //   try {
  //     const payload = {
  //       businessId: id,
  //       name: name,
  //       email: email,
  //     }
  //     await dispatch(InviteBusinessOwner(payload)).unwrap();
  //     closeModal()
  //   } catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //   }
  // };

  const addContract = async (id) => {
    try {
      const payload = {
        id: id,
        startDate: startDate,
        endDate: endDate,
        percentage: percentage,
      }
      await dispatch(addContractBusiness(payload)).unwrap();
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  // const HandleResendInvite = async (id) => {
  //   try {
  //     await dispatch(ResendInviteBusinessOwner({ id })).unwrap();
  //   } catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //   }
  // };


  const [nameFilter, setnameFilter] = useState('');
  const [emailFilter, setemailFilter] = useState('');
  const applyFilter = () => {
    setsearch(nameFilter)
    setsearchEmail(emailFilter)
    settoggle(false);
    navigate(`${location.pathname}`);
  }

  const resetFilter = () => {
    setnameFilter('');
    setemailFilter('');
    setsearch('');
    setsearchEmail('');
    settoggle(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);


  const BuisnessesList = async () => {
    try {
      setload(true);
      setbuisnesses(null);
      const response = await dispatch(getBusinessess({ isAdmin: isAdmin, page: currentPage, limit: 50, search: search, searchEmail: searchEmail })).unwrap();
      setbuisnesses(response?.data);
      settotalCount(response?.totalCount);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (location.pathname === "/admin-businesses") {
      setisAdmin(1);
    } else if (location.pathname === "/owner-businesses") {
      setisAdmin(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAdmin !== null) {
      BuisnessesList();
    }
  }, [isAdmin, currentPage, search, searchEmail]);


  const changeBusiness = async () => {
    try {
      const spacesIds = countrySpaces?.map(item => (item?._id))
      const payload = {
        countryId: countryId,
        businessName: businessName,
        currency: currency,
        type: "652e2474fc9208d21a338807",
        spaces: spacesIds,
      }
      await dispatch(changeBusinessName(payload)).unwrap();
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const profile = useSelector(getProfile)
  const pageTitle = "Businesses";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "changeBusiness" ?
          <ModalContent title={`Change Business for ${userDetail?.name}`} closeModal={closeModal} onClick={() => changeBusiness()} buttonText={countries?.length > 1 ? "Submit" : ""}>
            {countries?.length > 1 ? <>
              <select value={countryId} onChange={(e) => setcountryId(e.target.value)} className="form-select py-3 cw-select-field mb-3" style={{ paddingLeft: '10px', height: 'fit-content' }}>
                <option value="">Select Country</option>
                {countries?.map((data, i) => (
                  <option key={i} value={data?._id}>{data?.name}</option>
                ))}
              </select>
              <FloatingInput placeholder="New Business Name" value={businessName} onChange={(e) => setbusinessName(e.target.value)} />
            </> : <>
              <p>There is Additional Country</p>
            </>}
          </ModalContent>
          : modalType == "contract" ? (
            <>
              <ModalContent title={`Add Contract for ${userDetail?.name}`} closeModal={closeModal} onClick={() => addContract(businessId)} buttonText="Submit">
                <FloatingInput type="date" value={startDate} onChange={(e) => setstartDate(e.target.value)} placeholder="Start Date" bottomSpace={3} />
                <FloatingInput type="date" value={endDate} onChange={(e) => setendDate(e.target.value)} placeholder="End Date" bottomSpace={3} />
                <FloatingInput type="number" value={percentage} onChange={(e) => setpercentage(e.target.value)} placeholder="Commision Percentage" bottomSpace={3} />
              </ModalContent>
            </>) : null}
      </CustomModal>


      <ContentSection title="Businesses">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Businesses', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Businesses by {isAdmin === 0 ? "Owner" : "Admin"}</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="space-name" placeholder="Space Name" />
                <label for="space-name">Business Name</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="email" onChange={(e) => setemailFilter(e.target.value)} value={emailFilter} class="form-control" id="email-filter" placeholder="Email" />
                <label for="email-filter">Business Email</label>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">

          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Businesses : {totalCount}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Country</th>
                <th scope="col">Spaces</th>
                {isAdmin === 0 && <th scope="col">Email</th>}
                {isAdmin === 0 && <th scope="col">Verified</th>}
                <th scope="col">Date</th>
                <th scope="col" style={{ width: '50px' }}>Contract</th>
                <th scope="col" style={{ width: '50px' }}>Contract Percentage</th>
                <th scope="col" style={{ width: '50px' }}>Commision</th>
                {/*{isAdmin === 1 && <th scope="col">Invite</th>}*/}
                {isAdmin === 0 && <th scope="col" style={{ width: '50px' }}>Approve</th>}
                <th scope="col" style={{ width: '110px' }}>CW Verified</th>
                <th scope="col" style={{ width: '50px' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={13}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : buisnesses?.map((item, i) => (
                <tr key={i}>
                  <td style={{ verticalAlign: "middle" }}>{i + startIndex + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.country?.name}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.noOfSpaces}</td>
                  {isAdmin === 0 && <td style={{ verticalAlign: "middle" }}>{item?.businessOwner?.email}</td>}
                  {isAdmin === 0 && <td style={{ verticalAlign: "middle" }}>{item?.businessOwner?.isVerified === 1 ? "Yes" : "No"}</td>}
                  <td style={{ verticalAlign: "middle" }}>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.isContract ? "Yes" : "No"}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.contractPercentage}</td>
                  <td style={{ verticalAlign: "middle" }}>{(item?.totalCommission || 0).toFixed(2).toLocaleString("en-US")} {item?.currency}</td>
                  {/* {isAdmin === 1 && <td style={{ verticalAlign: "middle" }}>{item?.businessOwner?.isPassword === 0 ? <button onClick={() => HandleResendInvite(item?.businessOwner?._id)} disabled={item?.businessOwner?.isPassword === 1 && item?.isPackage === 0} className="btn cw-btn cw-yellow-btn px-3 py-1">Resend Invite</button> : <button disabled={item?.businessOwner?.isPassword === 1 && item?.isPackage === 0} onClick={() => viewModal(item, "user")} className="btn cw-btn cw-yellow-btn px-3 py-1">Invite</button>}</td>}*/}

                  {isAdmin === 0 && <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                      {/* {profile?.system_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)} */}
                      <div class="form-check form-switch cw-switch">
                        <input class="form-check-input" type="checkbox" role="switch"  id="business-aproved" disabled={item?.businessOwner?.isVerified === 0 || !permission?.write} checked={item?.isApproved == 1} onChange={() => AproveBusiness(item?._id)} />
                        <label class="form-check-label" for="business-aproved"></label>
                      </div>
                    </div>
                  </td>}
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                      <div class="form-check form-switch cw-switch">
                        <input class="form-check-input" type="checkbox" role="switch" di id="business-aproved" disabled={!permission?.write} checked={item?.isVerify == 1} onChange={() => VerifiedBusiness(item?._id)} />
                        <label class="form-check-label" for="business-aproved"></label>
                      </div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                    {permission?.write && <>{isAdmin === 1 && <div onClick={() => viewModal(item, "changeBusiness")} className={`cw-action-icons`}><ReplaceAll width="18" /></div>}</>}
                      {/* <div onClick={() => viewModal(item, "contract")} className={`cw-action-icons`}><ReceiptText width="18" /></div> */}
                      {item?.isContract && <a href={`${process.env.REACT_APP_IMGURL}/${item?.contractAttachment}`} target="_blank" className={`cw-action-icons`}><ReceiptText width="18" /></a>}
                      <Link to={`/business-detail/${item?._id}`} className={`cw-action-icons`}><Eye width="18" /></Link>
                      <Link to={`/business-spaces/${item?._id}`} className={`cw-action-icons`}><Building2 width="18" /></Link>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
          {totalCount > 50 &&
            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />}
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Buisnesses