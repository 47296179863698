import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import DeleteModal from "../../components/DeleteModal";
import { Pencil, Trash2 } from "lucide-react";
import Button from "../../components/elements/Button";
import Breadcrumb from "../../components/elements/Breadcrumb";
import ContentSection from "../../components/elements/ContentSection";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import HtmlTextEditor from "../../components/elements/HtmlTextEditor";
import { addContractTemplate, deleteContractTemplate, editContractTemplate, getContractTemplates, getProfile } from "../../store/apiSlice";
import { getPagePermission } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";


const ContractTemplates = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [content, setContent] = useState("");
  const [percentage, setPercentage] = useState("");
  const [contractTemplateList, setcontractTemplateList] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    if (type === "add") {
      setContent("");
      setPercentage("");
      setId("");
    } else if (type === "edit") {
      setContent(item?.content);
      setPercentage(item?.percentage);
      setId(item?._id);
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setContent("");
    setPercentage("");
    setId("");
  }

  const contractTemplateDelete = async (id) => {
    try {
      await dispatch(deleteContractTemplate(id)).unwrap();
      setIsOpen(false);
      setcontractTemplateList((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const ContractTemplatelist = async () => {
    try {
      setload(true);
      setcontractTemplateList(null);
      const response = await dispatch(getContractTemplates()).unwrap();
      setcontractTemplateList(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    ContractTemplatelist();
  }, []);


  const handleAddEdit = async (e) => {
    e.preventDefault();
    const payload = {
      content: content,
      percentage: percentage
    }
    try {
      if (id) {
        payload.id = id;
        await dispatch(editContractTemplate(payload)).unwrap();
      } else {
        await dispatch(addContractTemplate(payload)).unwrap();
      }
      closeModal()
      ContractTemplatelist();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleChange = (html) => {
    setContent(html);
  };


  const navigate = useNavigate();
  const profile = useSelector(getProfile)
  const pageTitle = "Contract Templates";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {(modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Contract Template`} closeModal={closeModal} onClick={handleAddEdit} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput type="number" value={percentage} onChange={(e) => setPercentage(e.target.value)} placeholder="Contract Percentage" bottomSpace={3} />
            <HtmlTextEditor value={content} onChange={handleChange} label="Long Description" />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => contractTemplateDelete(id)} label="Contract Template" />
        ) : null}
      </CustomModal>

      <ContentSection title="Contract Templates">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'contractTemplateList', link: "/contractTemplateList" }, { label: 'Contract Templates', active: true },]} />

        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Contract Templates</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">

          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          {permission?.write && <Button onClick={() => viewModal("", "add")}>Add Contract Template</Button>}
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Contract Templates : {contractTemplateList?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Percentage</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : contractTemplateList?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>Contract {i + 1}</td>
                  <td>{item?.percentage}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {permission?.write && <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>}
                      {permission?.delete && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default ContractTemplates