import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addReviewSpace, FeaturedSpace, getAllAdmins, getAllCountries, getAllSpacesByBusinesses, getCountryCity, getProfile, Trending2Space } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import { BarChart2, ExternalLink, Eye, Facebook, Instagram, Link2, Linkedin, Mail, MousePointerClick, Pencil, Phone, Star, Twitter, Youtube } from "lucide-react";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import Pagination from "../../components/elements/Pagination";
import moment from "moment";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import FloatingInput from "../../components/elements/FloatingInput";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import { getPagePermission } from "../../utils/Helper";


const SpacesByBusinesses = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [date, setdate] = useState("");
  const [review, setreview] = useState("");
  const [locations, setlocations] = useState(null);
  const [countries, setcountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);

  const [search, setsearch] = useState('');
  const [totalCount, settotalCount] = useState('');
  const [country, setcountry] = useState('');
  const [nameFilter, setnameFilter] = useState('');
  const [countryFilter, setcountryFilter] = useState('');
  const [cityFilter, setcityFilter] = useState('');
  const [dateFilter, setdateFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState("");
  const [isTrendingFilter, setIsTrendingFilter] = useState("");
  const [isFeaturedFilter, setIsFeaturedFilter] = useState("");
  const [isContractFilter, setIsContractFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);  // New state
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "analytics") {
      setUserDetail(item);
      setId(item?._id);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
    setname("");
    setdescription("");
    setdate("");
    setreview("");
  }

  const Locations = async () => {
    try {
      setload(true);
      setlocations(null);
      const response = await dispatch(getAllSpacesByBusinesses({ city: cityFilter, country: countryFilter, adminId: selectedUsers, page: currentPage, search: nameFilter, isTrending: isTrendingFilter, isFeatured: isFeaturedFilter, date: dateFilter, isContract: isContractFilter })).unwrap();
      setlocations(response?.data);
      settotalCount(response?.totalCount)
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    } finally {
      setload(false)
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Cities = async (countryId) => {
    try {
      setcities(null);
      const response = await dispatch(getCountryCity(countryId)).unwrap();
      setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Admins = async () => {
    try {
      setUniqueCreators(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setUniqueCreators(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (country) {
      Cities(country);
    }
  }, [country]);

  useEffect(() => {
    Countries();
    Admins();
  }, [])

  useEffect(() => {
    Locations();
  }, [currentPage, isFilterApplied])


  // const applyFilter = () => {
  //   Locations();
  //   navigate(`${location.pathname}`);
  //   settoggle(false);
  // };


  // const resetFilter = () => {
  //   setcountryFilter('');
  //   setcityFilter('');
  //   setdateFilter('');
  //   setnameFilter('');
  //   setselectedUsers([]);
  //   setIsFeaturedFilter("")
  //   setIsTrendingFilter("")
  //   setIsContractFilter("")

  //   Locations();
  //   settoggle(false);
  // };

  const applyFilter = () => {
    setIsFilterApplied(true);  // Set the state to true when filters are applied
    settoggle(false);  // Close toggle if necessary
  };

  const resetFilter = () => {
    setcountryFilter('');
    setcityFilter('');
    setdateFilter('');
    setnameFilter('');
    setselectedUsers([]);
    setIsFeaturedFilter("")
    setIsTrendingFilter("")
    setIsContractFilter("")

    setIsFilterApplied(false);  // Set the state to false when filters are reset
    settoggle(false); // Close toggle if necessary
  };

  // useEffect(() => {
  //     Locations();  
  // }, [isFilterApplied]); 



  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);


  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [searchValue1, setSearchValue1] = useState('');
  const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcountry(data?.id);
    setcountryFilter(data?._id)
    setShowDropdown(false);
  };

  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setSearchValue1(value);
    if (value.length > 0) {
      setShowDropdown1(true);
    } else {
      setShowDropdown1(false);
    }
  };
  const handleOptionClick1 = (data) => {
    setSearchValue1(data?.name);
    setcityFilter(data?._id);
    setShowDropdown1(false);
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const filteredCities = cities?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue1?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };

  const FeatureSpace = async (id) => {
    try {
      const spaceIndex = locations.findIndex(space => space?._id === id);
      if (spaceIndex !== -1) {
        const updatedSpaces = [...locations];
        updatedSpaces[spaceIndex].isFeatured = updatedSpaces[spaceIndex].isFeatured === 1 ? 0 : 1;
        setlocations(updatedSpaces);
      }
      await dispatch(FeaturedSpace(id)).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  const TrendingSpace = async (id) => {
    try {
      const spaceIndex = locations.findIndex(space => space?._id === id);
      if (spaceIndex !== -1) {
        const updatedSpaces = [...locations];
        updatedSpaces[spaceIndex].isTrending = updatedSpaces[spaceIndex].isTrending === 1 ? 0 : 1;
        setlocations(updatedSpaces);
      }
      await dispatch(Trending2Space(id)).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex1((prevIndex) =>
        prevIndex < filteredCities.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick1(filteredCities[highlightedIndex1]);
      }
    }
  };

  const addReview = async (id) => {
    try {
      const payload = {
        spaceId: id,
        name: name,
        description: description,
        date: date,
        rating: review,
      }
      await dispatch(addReviewSpace(payload)).unwrap();
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const pageTitle = "Spaces";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal} size={modalType === "analytics" ? "full" : "small"}>
        {modalType === "add-review" ? (
          <ModalContent title={`Add Review ${userDetail?.name}`} closeModal={closeModal} onClick={() => addReview(id)} buttonText="Submit">
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" bottomSpace={3} />
            <FloatingInput type="date" value={date} onChange={(e) => setdate(e.target.value)} placeholder="Name" bottomSpace={3} />
            <FloatingTextArea value={description} onChange={(e) => setdescription(e.target.value)} placeholder="Description" bottomSpace={3} />
            <p className="font-size-14 fw-semibold mb-1">Review</p>
            <div className="star-rating">
              {[1, 2, 3, 4, 5].map((index) => (
                <span key={index} onClick={() => setreview(index)} className={index <= review ? 'star-filled' : 'star-empty'}>
                  {index <= review ? <Star size={24} stroke="var(--color3)" fill="var(--color3)" /> : <Star size={24} stroke="var(--color3)" />}
                </span>
              ))}
            </div>
          </ModalContent>
        ) : modalType === "analytics" ? (
          <ModalContent title={`Analytics of ${userDetail?.name}`} closeModal={closeModal}>
            <div className="row gy-3 gx-3">
              {userDetail?.social?.faceBook && <div className="col-md-4">
                <div className="border p-3">
                  <Facebook size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Facebook</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.facebook} Clicks</p>
                </div>
              </div>}
              {userDetail?.social?.instagram && <div className="col-md-4">
                <div className="border p-3">
                  <Instagram size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Instagram</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.instagram} Clicks</p>
                </div>
              </div>}
              {userDetail?.social?.twitter && <div className="col-md-4">
                <div className="border p-3">
                  <Twitter size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">X / Twitter</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.twitter} Clicks</p>
                </div>
              </div>}
              {userDetail?.social?.linkedIn && <div className="col-md-4">
                <div className="border p-3">
                  <Linkedin size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">LinkedIn</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.linkedin} Clicks</p>
                </div>
              </div>}
              {userDetail?.social?.tiktok && <div className="col-md-4">
                <div className="border p-3">
                  <Linkedin size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Tiktok</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.tiktok} Clicks</p>
                </div>
              </div>}
              {userDetail?.social?.youtube && <div className="col-md-4">
                <div className="border p-3">
                  <Youtube size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Youtube</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.youtube} Clicks</p>
                </div>
              </div>}
              {userDetail?.spaceEmail && <div className="col-md-4">
                <div className="border p-3">
                  <Mail size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Email</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.email} Clicks</p>
                </div>
              </div>}
              {userDetail?.spacePhone && <div className="col-md-4">
                <div className="border p-3">
                  <Phone size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Phone</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.phone} Clicks</p>
                </div>
              </div>}
              {userDetail?.spacePhone && <div className="col-md-4">
                <div className="border p-3">
                  <img src="https://www.thecoworkingspaces.com/assets/images/whatsapp-icon.svg" height={34} alt="WhatsApp Icon" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">WhatsApp</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.whatsapp} Clicks</p>
                </div>
              </div>}
              {userDetail?.website && <div className="col-md-4">
                <div className="border p-3">
                  <Link2 size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Website</p>
                  <p className="font-size-16 mb-0">{userDetail?.analytics?.website} Clicks</p>
                </div>
              </div>}
              <div className="col-md-4">
                <div className="border p-3">
                  <MousePointerClick size={34} stroke="var(--color3)" className="mb-1" />
                  <p className="font-size-20 fw-bold mb-0">Space</p>
                  <p className="font-size-16 mb-0">{userDetail?.spaceopen} Clicks</p>
                </div>
              </div>
            </div>
          </ModalContent>
        ) : null}
      </CustomModal>


      <ContentSection title="Spaces By Businesses">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Spaces By Businesses', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Spaces By Businesses</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="space-name" placeholder="Space Name" />
                <label for="space-name">Space Name</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter} />
                <label for="date-filter">Date</label>
              </div>
              <div className="mb-3">
                <div class="form-floating cw-input">
                  <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off" />
                  <label for="country">Select Country</label>
                </div>
                {showDropdown && (
                  <div className="dropdown cw-select-dropdown">
                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                      {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                        <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                      ))}
                      {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {country && (
                <div className="mb-3">
                  <div class="form-floating cw-input ">
                    <input type="text" class="form-control" onKeyDown={handleKeyDown1} maxLength={120} onChange={(e) => handleInputChange1(e)} value={searchValue1} id="city" placeholder="city" autoComplete="off" />
                    <label for="city">City</label>
                  </div>
                  {showDropdown1 && (
                    <div className="dropdown cw-select-dropdown">
                      <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                          <li key={index} onClick={() => handleOptionClick1(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {cities?.length > 0 && cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                          <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              <div class="mb-3 sp-input-filter">
                <p class="font-size-20 fw-semibold mb-2">Trending / Featured / Contract</p>
                <div class="d-flex gap-2 flex-wrap">
                  <input type="checkbox" class="btn-check" id="trending" checked={isTrendingFilter === 1} onChange={() => setIsTrendingFilter(1)} />
                  <label class="btn cw-select-btn" for="trending">Trending</label>

                  <input type="checkbox" class="btn-check" id="featured" checked={isFeaturedFilter === 1} onChange={() => setIsFeaturedFilter(1)} />
                  <label class="btn cw-select-btn" for="featured">Featured</label>

                  <input type="checkbox" class="btn-check" id="contract" checked={isContractFilter === 1} onChange={() => setIsContractFilter(1)} />
                  <label class="btn cw-select-btn" for="contract">Contract</label>
                </div>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            {permission?.write && <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => navigate("/add-space")}>Add Space</button>}
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Total Spaces: {totalCount}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Business Name</th>
                <th scope="col">Location</th>
                <th scope="col">Capacity</th>
                <th scope="col">Email</th>
                <th scope="col">Date</th>
                <th scope="col">Feature</th>
                <th scope="col">Trending</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>

              {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={13}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : locations?.map((item, i) => (
                <tr key={i}>
                  <td style={{ verticalAlign: "middle" }}>{i + startIndex + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex gap-2 align-items-center">
                      {item?.businesses?.name}
                    </div>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{item?.cities?.name}, {item?.countries?.name}</td>
                  <td style={{ verticalAlign: "middle" }}><div className="d-flex gap-2 justify-content-between">
                    <div className="cw-count">
                      <p className="font-size-12 mb-0">{item?.totalDesk}</p>
                      <p className="font-size-10 mb-0">Desk</p>
                    </div>
                    <div className="cw-count">
                      <p className="font-size-12 mb-0">{item?.totalRooms}</p>
                      <p className="font-size-10 mb-0">Room</p>
                    </div>
                  </div></td>
                  <td style={{ verticalAlign: "middle" }}>{item?.spaceEmail}</td>
                  <td className="text-capitalize" style={{ verticalAlign: "middle" }}>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                      <div class="form-check form-switch cw-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="business-aproved" disabled={!permission?.write} checked={item?.isFeatured == 1} onChange={() => FeatureSpace(item?._id)} />
                        <label class="form-check-label" for="business-aproved"></label>
                      </div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                      <div class="form-check form-switch cw-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="business-aproved" disabled={!permission?.write} checked={item?.isTrending == 1} onChange={() => TrendingSpace(item?._id)} />
                        <label class="form-check-label" for="business-aproved"></label>
                      </div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                      {(item?.analytics || item?.spaceopen) && <div onClick={() => viewModal(item, "analytics")} className={`cw-action-icons l-blue-bg`}><BarChart2 width="18" /></div>}
                      {permission?.write && <div onClick={() => viewModal(item, "add-review")} className={`cw-action-icons l-blue-bg`}>{item?.totalReviews === 0 ? <><Star width="18" /></> : <><Star width="18" fill="var(--color2)" /></>}</div>}
                      <Link target="blank" to={`https://www.thecoworkingspaces.com/space/${item?.slug}`} className={`cw-action-icons l-blue-bg`}><ExternalLink width="18" /></Link>
                      <Link to={`/space-details/${item?._id}`} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></Link>
                      {permission?.write && <Link to={`/edit-space/${item?._id}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
          {totalCount > 50 &&
            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />}
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default SpacesByBusinesses