import React, { useEffect, useState, useContext, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Nav from "./Navbar";
import SidebarAdmin from "./Sidebar";
import { context } from "../context/context";
import DashboardAdmin from "../pages/core/DashboardAdmin";
import Cities from "../pages/core/Cities";
import Countries from "../pages/core/Countries";
import Features from "../pages/core/Features";
import FeatureCategories from "../pages/core/FeatureCategories";
import Blogs from "../pages/core/Blogs";
import BlogCategories from "../pages/core/BlogCategories";
import AddBlog from "../pages/core/AddBlog";
import Admin from "../pages/core/Admin";
import AddAdmin from "../pages/core/AddAdmin";
import Locations from "../pages/core/Locations";
import LocationDetails from "../pages/core/LocationDetails";
import AddLocation from "../pages/core/AddLocation";
import Users from "../pages/core/Users";
import Login from "../pages/auth/Login";
import { useDispatch, useSelector } from "react-redux";
import Inclusions from "../pages/core/Inclusions";
import ClaimSpaces from "../pages/core/ClaimSpaces";
import ReportSpaces from "../pages/core/ReportSpaces";
import RequestQuoteSpaces from "../pages/core/RequestQuoteSpaces";
import LandlordProperty from "../pages/core/LandlordProperty";
import Buisnesses from "../pages/core/Buisnesses";
import SpacesByBusinesses from "../pages/core/SpacesByBusinesses";
import UserCustomers from "../pages/core/UserCustomers";
import Chat from "../pages/core/Chat";
import Careers from "../pages/core/Careers";
import ContactUsList from "../pages/core/ContactUsList";
import DownloadData from "../pages/core/DownloadData";
import EnterpriseSolutions from "../pages/core/EnterpriseSolutions";
import NewsletterEmails from "../pages/core/NewsletterEmails";
import MetaData from "../pages/core/MetaData";
import AbandonedSpaces from "../pages/core/AbandonedSpaces";
import PushEmail from "../pages/core/PushEmail";
import BookedTours from "../pages/core/BookedTours";
import RoomDeskTypes from "../pages/core/RoomDeskTypes";
import { getDataProfile, getUserID, getUserRole, getUserToken } from "../store/apiSlice";
import Areas from "../pages/core/Areas";
import SendEmail from "../pages/core/SendEmail";
import ContractTemplates from "../pages/core/ContractTemplates";
import BusinessDetail from "../pages/core/BusinessDetail";
import ContractBusinesses from "../pages/core/ContractBusinesses";
import BusinessLocation from "../pages/core/BusinessLocation";
import SpacePricing from "../pages/core/SpacePricing";


const Layout = () => {
  const dispatch = useDispatch();
  const id = useSelector(getUserID)
  const authToken = useSelector(getUserToken)
  const role = useSelector(getUserRole)
  const location = useLocation();
  const isFirstRender = useRef(true);

  const getProfileData = async () => {
    try {
      await dispatch(getDataProfile(id)).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Check if the page is being loaded directly (not through navigation)
      if (id) {
        getProfileData();
      }
      isFirstRender.current = false;
    }
  }, [id, location]);


  return (
    <>
      <Content authtoken={authToken} role={role} location={location} />
    </>
  );
};


const Content = ({ authtoken, role, location }) => {
  return (
    <>
      {authtoken && (role === "admin" || role === "sub-admin") ? <>
        {location.pathname === "/login" ? <></> : <Nav />}
        {location.pathname === "/login" ? <></> : <SidebarAdmin />}

        <section className={location.pathname == "/login" ? "" : "content-section"}>
          <>
            <Routes>
              <Route path="*" element={<DashboardAdmin />} />
              <Route path="/" element={<DashboardAdmin />} />
              <Route path="/cities" element={<Cities />} />
              <Route path="/areas" element={<Areas />} />
              <Route path="/countries" element={<Countries />} />
              <Route path="/features" element={<Features />} />
              <Route path="/feature-categories" element={<FeatureCategories />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog-categories" element={<BlogCategories />} />
              <Route path="/add-blog" element={<AddBlog />} />
              <Route path="/edit-blog/:id" element={<AddBlog />} />
              <Route path="/admins" element={<Admin />} />
              <Route path="/add-admin" element={<AddAdmin />} />
              <Route path="/edit-admin" element={<AddAdmin />} />
              <Route path="/Spaces" element={<Locations />} />
              <Route path="/add-space" element={<AddLocation />} />
              <Route path="/edit-space/:id" element={<AddLocation />} />
              <Route path="/space-details/:id" element={<LocationDetails />} />
              <Route path="/business-users" element={<Users />} />
              <Route path="/customer-users" element={<UserCustomers />} />
              <Route path="/inclusions" element={<Inclusions />} />
              <Route path="/room-types" element={<RoomDeskTypes />} />
              <Route path="/desk-types" element={<RoomDeskTypes />} />
              <Route path="/virtual-office-types" element={<RoomDeskTypes />} />
              <Route path="/claim-spaces" element={<ClaimSpaces />} />
              <Route path="/report-spaces" element={<ReportSpaces />} />
              <Route path="/user-requested-quotes" element={<RequestQuoteSpaces />} />
              <Route path="/guest-requested-quotes" element={<RequestQuoteSpaces />} />
              <Route path="/landlord-property" element={<LandlordProperty />} />
              <Route path="/admin-businesses" element={<Buisnesses />} />
              <Route path="/owner-businesses" element={<Buisnesses />} />
              <Route path="/contract-businesses" element={<ContractBusinesses />} />
              <Route path="/business-spaces" element={<SpacesByBusinesses />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/contact-us-list" element={<ContactUsList />} />
              <Route path="/download-data" element={<DownloadData />} />
              <Route path="/enterprise-solutions" element={<EnterpriseSolutions />} />
              <Route path="/newsletter-emails" element={<NewsletterEmails />} />
              <Route path="/meta-data" element={<MetaData />} />
              <Route path="/abandoned-spaces" element={<AbandonedSpaces />} />
              <Route path="/claim-invitation" element={<PushEmail />} />
              <Route path="/booked-tour-list" element={<BookedTours />} />
              <Route path="/send-emails" element={<SendEmail />} />
              <Route path="/contract-templates" element={<ContractTemplates />} />
              <Route path="/business-detail/:businessId" element={<BusinessDetail />} />
              <Route path="/business-spaces/:businessId" element={<BusinessLocation />} />
              <Route path="/space-pricing/:spaceId" element={<SpacePricing />} />
            </Routes>
          </>
        </section>
      </> :
        <>
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/" element={<Login />} />
          </Routes>
        </>
      }
    </>
  );
};

export default Layout;