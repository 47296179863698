import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addAdmin, editAdmin, getProfile, } from "../../store/apiSlice";
import Spinner from "../../components/Spinner";
import FloatingInput from "../../components/elements/FloatingInput";
import SelectButton from "../../components/elements/SelectButton";
import Button from "../../components/elements/Button";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import { getPagePermission, menuItems } from "../../utils/Helper";
import Table from "../../components/elements/Table";

const generateKeysFromMenu = (menuItems) => {
  return menuItems.reduce((acc, item) => {
    if (item.label !== "Dashboard") { // Exclude Dashboard
      const key = item.label
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .split(" ") // Split into words
        .map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize after the first word
        )
        .join(""); // Join words together

      acc[key] = { read: false, write: false, delete: false };
    }
    return acc;
  }, {});
};

const AddAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [imageName, setimageName] = useState();
  const [permissions, setPermissions] = useState(generateKeysFromMenu(menuItems));
  const [load, setload] = useState(false);

  useEffect(() => {
    if (location?.state?._id) {
      setId(location?.state?._id?._id)
      setname(location?.state?._id?.name)
      setemail(location?.state?._id?.email)
      setimageName(location?.state?._id?.imageName)
      setPermissions(location?.state?._id?.permissions?.areas ? location?.state?._id?.permissions : generateKeysFromMenu(menuItems));
    }
    else {
      setId("")
      setname("")
      setemail("")
      setimageName("")
    }
  }, []);

  const addandEditSubAdmin = async (e) => {
    setload(true)
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name)
      formData.append("email", email)
      formData.append("password", password)
      formData.append("adminImage", imageName)
      formData.append("permissions", JSON.stringify(permissions));
      if (id) {
        formData.append("id", id)
        await dispatch(editAdmin(formData)).unwrap();
      } else {
        await dispatch(addAdmin(formData)).unwrap();
      }
      navigate("/admins")
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log("permissions", permissions);


  const handlePermissionChange = (key, action) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [key]: {
        ...prevPermissions[key],
        [action]: !prevPermissions[key][action], // Toggle selected permission
        read: action === "read"
          ? !prevPermissions[key].read // Toggle read normally
          : true // Ensure read is true if write/delete is selected
      }
    }));
  };


  const profile = useSelector(getProfile)
  const pageTitle = "Admin";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);


  const handleSelectRow = (key) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [key]: {
        read: true,
        write: true,
        delete: true
      }
    }));
  };
  

  return (
    <>
      {load && <Spinner />}
      <ContentSection title={`${location.pathname === "/add-admin" ? "Add" : "Edit"} Admin User`}>
        <Breadcrumb items={[{ label: 'Menu', }, { label: `${location.pathname === "/add-admin" ? "Add" : "Edit"} Admin User`, active: true },]} />
        <Title bottomSpace={2}>{location.pathname === "/add-admin" ? "Add" : "Edit"} Admin User</Title>
        <BoxSection bottomSpace={4}>
          <div className="row gy-3">
            <div className="col-md-6">
              <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="password" value={password} onChange={(e) => setpassword(e.target.value)} placeholder="Password" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Profile Image" />
            </div>
            <div className="col-md-12">
              <Table>
                <thead>
                  <tr>
                    <th>Permission</th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Delete</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(permissions).map(([key, value]) => (
                    <tr key={key}>
                      <td style={{verticalAlign: "middle"}} className="text-capitalize">{key.replace(/([A-Z])/g, " $1").trim()}</td> {/* Convert camelCase to spaced words */}
                      <td style={{verticalAlign: "middle"}}>
                        <input
                          type="checkbox"
                          checked={value?.read}
                          onChange={() => handlePermissionChange(key, "read")}
                        />
                      </td>
                      <td style={{verticalAlign: "middle"}}>
                        <input
                          type="checkbox"
                          checked={value?.write}
                          onChange={() => handlePermissionChange(key, "write")}
                        />
                      </td>
                      <td style={{verticalAlign: "middle"}}>
                        <input
                          type="checkbox"
                          checked={value?.delete}
                          onChange={() => handlePermissionChange(key, "delete")}
                        />
                      </td>
                      <td style={{verticalAlign: "middle"}}>
                        <Button variant="black" size="small" className="font-size-12 py-1 px-2" onClick={() => handleSelectRow(key)}>Select All</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </BoxSection>

        <div class="d-flex justify-content-center gap-2">
          <Button onClick={() => navigate("/admins")} variant="black-outline" size="small">Cancel</Button>
          <Button onClick={addandEditSubAdmin} size="small" disabled={!permission?.write}>{location.pathname === "/add-admin" ? "Create" : "Update"}</Button>
        </div>
      </ContentSection>
    </>
  )
}

export default AddAdmin