import React, { useEffect, useState } from 'react'
import ContentSection from '../../components/elements/ContentSection'
import { useDispatch, useSelector } from 'react-redux';
import BoxSection from '../../components/elements/BoxSection';
import Table from '../../components/elements/Table';
import Title from '../../components/elements/Title';
import Breadcrumb from '../../components/elements/Breadcrumb';
import { addSpacePrice, getBusinessDetail, getPricingBySpace, getProfile, getRoomDeskTypes, SpaceDetail } from '../../store/apiSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomModal from '../../components/elements/Modal';
import ModalContent from '../../components/elements/ModalContent';
import TextDetail from '../../components/elements/TextDetail';
import { Eye, Pencil } from 'lucide-react';
import moment from 'moment';
import { getPagePermission } from '../../utils/Helper';
import Button from '../../components/elements/Button';
import FloatingInput from '../../components/elements/FloatingInput';

const SpacePricing = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const urlParts = window.location.pathname.split('/');
    const spaceId = urlParts[urlParts.length - 1];

    const [detail, setdetail] = useState({})
    const [load, setload] = useState(true)
    const [pricing, setpricing] = useState([])
    const [solutionTypes, setsolutionTypes] = useState([]);

    const getDetail = async () => {
        try {
            const response = await dispatch(SpaceDetail(spaceId)).unwrap();
            setdetail(response?.data[0]);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getPricing = async () => {
        try {
            const response = await dispatch(getPricingBySpace(spaceId)).unwrap();
            setpricing(response?.data?.[0]?.pricing);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        } finally {
            setload(false);
        }
    };

    const Type = async () => {
        try {
            const response = await dispatch(getRoomDeskTypes()).unwrap();
            setsolutionTypes(response?.data);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        Type();
    }, []);

    useEffect(() => {
        if (spaceId) {
            window.scrollTo({ top: 0, left: 0 })
            getDetail();
            getPricing();
        }
    }, [spaceId])

    const HowManyPeople = ["Single Person", "2 - 4", "5 - 8", "9 - 12", "13 - 20", "More than 20 people"]
    const [solution, setsolution] = useState("")
    const [solutionName, setsolutionName] = useState("")
    const [noOfPeople, setnoOfPeople] = useState(null)
    const [listOfPaymentCycles, setListOfPaymentCycles] = useState([]);
    const [price, setprice] = useState("");

    const handleSolutionSelection = (data) => {
        setsolution(data?._id);
        setsolutionName(data?.name);
        const matchedType = data
        if (matchedType) {
            const sortedPaymentCycles = [...(matchedType?.paymentcycles || [])].sort((a, b) => {
                const sortingKeyA = typeof a?.SortingKey === 'number' && isFinite(a?.SortingKey) ? a?.SortingKey : Infinity;
                const sortingKeyB = typeof b?.SortingKey === 'number' && isFinite(b?.SortingKey) ? b?.SortingKey : Infinity;
                return sortingKeyA - sortingKeyB;
            });
            setListOfPaymentCycles(sortedPaymentCycles);
            setSelectedPaymentCycle(sortedPaymentCycles?.[0]);
            setprice("");
        }
    };

    const [selectedPaymentCycle, setSelectedPaymentCycle] = useState(null);


    const handleSubmit = async () => {
        try {
            const payload = {
                spaceId: detail?._id,
                price: {
                    solution: solution,
                    paymentCycle: selectedPaymentCycle?._id,
                    noOfPeople: noOfPeople ? noOfPeople : null,
                    price: price
                }
            }
            const response = await dispatch(addSpacePrice(payload)).unwrap();
            await getPricing();
            closeModal();
        } catch (error) {
            console.log(error, "Error");
        }
    }


    useEffect(() => {
        if (detail) {
            document.title = `Pricing for ${detail?.name}`
        }
    }, [detail, location.pathname]);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setmodalType] = useState(false);

    function viewModal(type, item) {
        setmodalType(type);
        // setUserDetail(item || null);
        setIsOpen(true);
        if (type === "edit") {
            setsolution(item?.solution?._id)
            setsolutionName(item?.solution?.name)
            setnoOfPeople(item?.noOfPeople || null)
            setSelectedPaymentCycle(item?.paymentCycle);
            setprice(item?.price);
            const matchedType = solutionTypes?.find((type) => type?._id === item?.solution?._id)
            if (matchedType) {
                const sortedPaymentCycles = [...(matchedType?.paymentcycles || [])].sort((a, b) => {
                    const sortingKeyA = typeof a?.SortingKey === 'number' && isFinite(a?.SortingKey) ? a?.SortingKey : Infinity;
                    const sortingKeyB = typeof b?.SortingKey === 'number' && isFinite(b?.SortingKey) ? b?.SortingKey : Infinity;
                    return sortingKeyA - sortingKeyB;
                });
                setListOfPaymentCycles(sortedPaymentCycles);
            }
        }
    }


    function closeModal() {
        setIsOpen(false);
        setsolution("")
        setsolutionName("")
        setnoOfPeople("")
        setListOfPaymentCycles([])
        setprice("")
        setSelectedPaymentCycle("")
    }

    const handlePrice = (e) => {
        let value = e.target.value.trim(); // Remove leading/trailing spaces

        // Allow only numeric values (prevent e, +, - inputs)
        if (!/^\d*$/.test(value)) return;

        setprice(Number(value)); // Convert to number and update state
    };

    const profile = useSelector(getProfile)
    const navigate = useNavigate();
    const pageTitle = "Spaces";
    const permission = getPagePermission(pageTitle, profile);
    console.log("Permission:", permission);

    useEffect(() => {
        if (permission && permission.read === false) {
            navigate("/");
        }
    }, [permission, navigate]);


    return (
        <>
            <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal} size='big'>
                <ModalContent title={modalType === "edit" ? "Edit Pricing" : "Add Pricing"} closeModal={closeModal} onClick={handleSubmit} buttonText={modalType === "edit" ? "Update" : "Submit"}>
                    <p className="font-size-16 fw-semibold mb-1">Solution</p>
                    <div className="d-flex flex-wrap gap-2 mb-3">
                        {solutionTypes?.map((type, i) => (
                            <>
                                <input className="btn-check" type="radio" name="solution" id={type?._id} onChange={() => handleSolutionSelection(type)} checked={solution.includes(type?._id)} />
                                <label key={i} className="btn cw-btn cw-white-btn font-size-12 py-2 px-2 d-flex flex-column gap-2 align-items-center" for={type?._id} style={{ width: "auto" }}>
                                    <img src={`${process.env.REACT_APP_IMGURL}/${type?.imageName}`} alt={type?.name} width={40} />
                                    {type?.name}
                                </label>
                            </>
                        ))}
                    </div>
                    {solution && (
                        <>
                            <p className="font-size-16 fw-semibold mb-1">Select Payment Cycle</p>
                            <div className="d-flex flex-wrap gap-2 mb-3">
                                {listOfPaymentCycles?.map((item, i) => (
                                    <React.Fragment key={i}>
                                        <input type="radio" className="btn-check" name="paymentCycle" id={item?._id} checked={selectedPaymentCycle?._id === item?._id} onChange={() => setSelectedPaymentCycle(item)} />
                                        <label className="btn cw-btn cw-white-btn font-size-12 py-2 px-2" htmlFor={item?._id}>{item?.name}</label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                    )}
                    {selectedPaymentCycle?._id &&
                        <FloatingInput type="number" value={price} onChange={handlePrice} placeholder={`Price for ${selectedPaymentCycle?.name}`} bottomSpace={3} />}
                    {(solutionName === "Meeting Room" || solutionName === "Private Office") && <>
                        <p className="font-size-16 fw-semibold mb-1">No Of People</p>
                        <div className="d-flex gap-2 flex-wrap mb-4">
                            {HowManyPeople.map((data, i) => (
                                <React.Fragment key={i}>
                                    <input type="radio" name="manyPeople" className="btn-check" onChange={() => setnoOfPeople(data)} id={`manyPeople-${data}-checked`} checked={noOfPeople === data} />
                                    <label className="btn cw-btn cw-white-btn font-size-12 py-2 px-2" htmlFor={`manyPeople-${data}-checked`}>{data}</label>
                                </React.Fragment>
                            ))}
                        </div>
                    </>}
                </ModalContent>
            </CustomModal>

            <ContentSection title="Space Pricing">
                <Breadcrumb items={[{ label: 'Menu', }, { label: "Businesses", link: "/" }, { label: 'Space Pricing', active: true },]} />
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Title>Pricing for {detail?.name}</Title>
                    <Button size="small" onClick={() => viewModal("add")}>Add Pricing</Button>
                </div>
                <BoxSection>
                    <p className="font-size-20 pink-color">Pricing: {pricing?.length}</p>
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">Solution</th>
                                <th scope="col">Price Cycle</th>
                                <th scope="col">Price</th>
                                <th scope="col">No Of People</th>
                                <th style={{ width: '100px' }} scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load ? new Array(6).fill(null).map((_, index) => (<tr key={index}><td colSpan={9}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : pricing?.map((data, i) => (
                                <tr key={i}>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.solution?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.paymentCycle?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.price}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.noOfPeople || ""}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>
                                        <div className="d-flex align-items-center gap-2">
                                            <div className={`cw-action-icons`} onClick={() => viewModal("edit", data)}><Pencil width="18" /></div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </BoxSection>
            </ContentSection>
        </>
    )
}

export default SpacePricing