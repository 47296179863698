import { useDispatch, useSelector } from "react-redux";
import { getEnterpriseSoltions, getProfile } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import { Eye, Trash2 } from "lucide-react";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import { getPagePermission } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";


const EnterpriseSolutions = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [enterpriseSoltions, setenterpriseSoltions] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const enterpriseSolutionDelete = async (id) => {
    try {
      // await dispatch(deleteEnterpriseSoltion(id)).unwrap();
      setIsOpen(false);
      EnterpriseSolutionList();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const EnterpriseSolutionList = async () => {
    try {
      setload(true);
      setenterpriseSoltions(null);
      const response = await dispatch(getEnterpriseSoltions()).unwrap();
      setenterpriseSoltions(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    EnterpriseSolutionList();
  }, []);


  const navigate = useNavigate();
  const pageTitle = "Enterprise Solutions";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Customer Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.name} />
            <TextDetail label="Email" data={userDetail?.email} />
            <TextDetail label="Company Name" data={userDetail?.companyName} />
            <TextDetail label="Job Title" data={userDetail?.jobTitle} />
            <TextDetail label="Employees" data={userDetail?.employees} />
            <TextDetail label="Message" data={userDetail?.message} />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => enterpriseSolutionDelete(id)} label="Enterprise Solution" />
        ) : null}
      </CustomModal>

      <ContentSection title="Enterprise Solutions">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Enterprise Solutions', active: true },]} />
        <Title bottomSpace={2}>Enterprise Solutions</Title>

        <BoxSection>
          <p className="font-size-20 pink-color">Enterprise Solutions : {enterpriseSoltions?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Company Name</th>
                <th scope="col">Employees</th>
                <th scope="col">Job Title</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : enterpriseSoltions?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.email}</td>
                  <td>{item?.companyName}</td>
                  <td>{item?.employees}</td>
                  <td>{item?.jobTitle}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>
                      {permission?.delete && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default EnterpriseSolutions