import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAdmin, getAllAdmins, getProfile } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import DeleteModal from "../../components/DeleteModal";
import Button from "../../components/elements/Button";
import { Pencil, Trash2 } from "lucide-react";
import Breadcrumb from "../../components/elements/Breadcrumb";
import ContentSection from "../../components/elements/ContentSection";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import Title from "../../components/elements/Title";
import { getPagePermission } from "../../utils/Helper";


const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [admins, setadmins] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [load, setload] = useState(false);

  function viewModal(item) {
    setId(item);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const adminDelete = async (id) => {
    try {
      await dispatch(deleteAdmin(id)).unwrap();
      setIsOpen(false);
      setadmins((prevAdmins) => prevAdmins?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Admins = async () => {
    try {
      setload(true);
      const response = await dispatch(getAllAdmins()).unwrap();
      setadmins(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Admins();
  }, []);

  const profile = useSelector(getProfile)
  const pageTitle = "Admin";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);


  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <DeleteModal closeModal={closeModal} onClick={() => adminDelete(id)} label="Admin" />
      </CustomModal>

      <ContentSection title="Admins">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Admins', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Admins</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">

          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          {permission?.write && <Button onClick={() => navigate("/add-admin")}>Add Admin</Button>}
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Admins : {admins?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : admins?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.email}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                    {permission?.write && <div onClick={() => navigate("/edit-admin", { state: { _id: item }, })} className={`cw-action-icons`}><Pencil width="18" /></div>}
                      {permission?.delete && <div onClick={() => viewModal(item?._id)} className={`cw-action-icons`}><Trash2 width="18" /></div>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Admin