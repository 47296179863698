import React from 'react'

const TextDetail = ({ label, data, topSpace = 0, bottomSpace = 4, }) => {
  const validTopSpace = Math.min(Math.max(topSpace, 0), 5);
  const validBottomSpace = Math.min(Math.max(bottomSpace, 0), 5);
  return (
    <>
      <p class="font-size-16 black-color fw-bold mb-0">{label}</p>
      <p class={`font-size-16 black-color mb-${validBottomSpace}`}>{data}</p>
    </>
  )
}

export default TextDetail