import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile, getSpacesByBusiness } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import Pagination from "../../components/elements/Pagination";
import moment from "moment";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import { getPagePermission } from "../../utils/Helper";
import DynamicSelect from "../../components/elements/DynamicSelect";

const BusinessLocation = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const profile = useSelector(getProfile)
    const urlParts = window.location.pathname.split('/');
    const businessId = urlParts[urlParts.length - 1];
    const [locations, setlocations] = useState(null);
    const [cities, setcities] = useState([]);
    const [load, setload] = useState(false);

    const [search, setsearch] = useState('');
    const [totalCount, settotalCount] = useState('');
    const [cityFilter, setcityFilter] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

    const Locations = async () => {
        try {
            setload(true);
            setlocations(null);
            const response = await dispatch(getSpacesByBusiness({ city: cityFilter, businessId: businessId, page: currentPage })).unwrap();
            setlocations(response?.data);
            settotalCount(response?.totalCount)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        } finally {
            setload(false)
        }
    };

    useEffect(() => {
        if (businessId) {
            Locations();
        }
    }, [currentPage, cityFilter, businessId])

    useEffect(() => {
        if (locations?.length > 0) {
            const uniqueCities = new Map();

            locations.forEach(data => {
                if (data?.cities) {
                    const { name, country_name, _id } = data.cities;
                    if (!uniqueCities.has(_id)) {
                        uniqueCities.set(_id, { name, country: country_name, _id });
                    }
                }
            });

            const getCities = Array.from(uniqueCities.values()); // Convert Map to array

            setcities(getCities);
            console.log(getCities, "getCities");
        }
    }, [locations]);




    const handlePageChange = (page) => {
        navigate(`${location.pathname}?page=${page}`);
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (location.search) {
            const queryParams = new URLSearchParams(location.search);
            const pageParam = queryParams.get('page');
            const parsedPage = parseInt(pageParam, 10) || 1;
            setCurrentPage(parsedPage);
        }
    }, [location.search]);

    const pageTitle = "Spaces";
    const permission = getPagePermission(pageTitle, profile);
    console.log("Permission:", permission);

    useEffect(() => {
        if (permission && permission.read === false) {
            navigate("/");
        }
    }, [permission, navigate]);

    return (
        <>
            <ContentSection title="Spaces By Businesses">
                <Breadcrumb items={[{ label: 'Menu', }, { label: 'Spaces By Businesses', active: true },]} />
                <div className="row gy-3 mb-4 align-items-center mb-2">
                    <div className="col-md-3 col-6 order-1">
                        <Title>Spaces By Businesses</Title>
                    </div>
                    <div className="col-md-6 order-md-2 order-3 align-slef-center">
                        <DynamicSelect options={cities} optionLabel="name" optionValue="_id" onChange={(e) => setcityFilter(e.target.value)} value={cityFilter} placeholder="City Filter" />
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2">
                    </div>
                </div>
                <BoxSection>
                    <p className="font-size-20 pink-color">Total Spaces: {totalCount}</p>
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name</th>
                                <th scope="col">City</th>
                                <th scope="col">Country</th>
                            </tr>
                        </thead>
                        <tbody>

                            {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={13}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : locations?.map((item, i) => (
                                <tr key={i}>
                                    <td style={{ verticalAlign: "middle" }}>{i + startIndex + 1}</td>
                                    <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{item?.cities?.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{item?.cities?.country_name}</td>
                                </tr>))}
                        </tbody>
                    </Table>
                    {totalCount > 50 &&
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />}
                </BoxSection>
            </ContentSection>
        </>
    )
}

export default BusinessLocation