import ContextProvider from "./context/context"
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter >
      <Layout />
    </BrowserRouter>
  );
}

export default App;
