import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addCityArea, addSpace, editSpace, EditSpaceDetail, getAllCountries, getCityArea, getCountryCity, getCoworkingTypes, getFeatures, getProfile, getRoomDeskTypes } from "../../store/apiSlice";
import Spinner from "../../components/Spinner";
import FloatingInput from "../../components/elements/FloatingInput";
import SelectButton from "../../components/elements/SelectButton";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import DropdownSearch from "../../components/elements/DropdownSearch";
import Button from "../../components/elements/Button";
import ImageUpload from "../../components/elements/ImageUpload";
import toast from "react-hot-toast";
import generateSlug from "../../utils/GenerateSlug";
import BoxSection from "../../components/elements/BoxSection";
import ContentSection from "../../components/elements/ContentSection";
import Title from "../../components/elements/Title";
import Breadcrumb from "../../components/elements/Breadcrumb";
import { getPagePermission } from "../../utils/Helper";


const AddLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const l = useLocation();
  const path = l?.state;

  const [load, setload] = useState(false);
  const [singleLocation, setsingleLocation] = useState(true);
  const [countries, setcountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [areas, setareas] = useState([]);
  const [features, setfeatures] = useState(null);
  const [Types, setTypes] = useState(null);
  const [coworkingTypes, setcoworkingTypes] = useState(null);
  const [editLocation, seteditLocation] = useState(null);

  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [businessname, setbusinessname] = useState("");
  const [value, setvalue] = useState("");
  const [overview, setoverview] = useState("");
  const [type, settype] = useState("");

  const [contact_person, setcontact_person] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [website, setwebsite] = useState("");
  const [postCode, setpostCode] = useState("");
  const [address, setaddress] = useState("");
  const [currency, setcurrency] = useState("");
  const [country, setcountry] = useState("");
  const [mycities, setmycities] = useState("");
  const [area, setarea] = useState("");
  const [cityCountry, setcityCountry] = useState("");
  const [location, setlocation] = useState("");
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);

  const [spaceStartTime, setspaceStartTime] = useState("");
  const [spaceEndTime, setspaceEndTime] = useState("");
  const [operationDays, setoperationDays] = useState([]);
  const [is24Hours, setis24Hours] = useState("");
  const [daysType, setdaysType] = useState("");
  const [isTimeDisabled, setisTimeDisabled] = useState(false);

  const [spaceStartTimeTour, setspaceStartTimeTour] = useState("");
  const [spaceEndTimeTour, setspaceEndTimeTour] = useState("");
  const [operationDaysTour, setoperationDaysTour] = useState([]);
  const [is24HoursTour, setis24HoursTour] = useState("");
  const [daysTypeTour, setdaysTypeTour] = useState("");
  const [isTimeDisabledTour, setisTimeDisabledTour] = useState(false);

  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [tiktok, settiktok] = useState("")

  const [dp, setdp] = useState("")
  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [editImages, setEditImages] = useState([]);

  const [geocountry, setgeocountry] = useState("");
  const [geocity, setgeocity] = useState("");
  const [geoarea, setgeoarea] = useState("");
  const [geopostcode, setgeopostcode] = useState("");

  const urlParts = window.location.pathname.split('/');
  const spaceId = urlParts[urlParts.length - 1];


  if (value) {
    geocodeByPlaceId(value?.value?.place_id)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
        const area = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name
          || addressComponents.find(component => component.types.includes('route'))?.long_name
          || addressComponents.find(component => component.types.includes('street_address'))?.long_name;
        const postcode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name;

        setgeocountry(country || "");
        setgeocity(city || "");
        setgeoarea(area || "");
        setgeopostcode(postcode || "");
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setlat(lat);
        setlong(lng);
        setlocation(value?.label);
      })
      .catch((error) => console.error(error));
  }


  const CoworkingType = async () => {
    try {
      setcoworkingTypes(null);
      const response = await dispatch(getCoworkingTypes()).unwrap();
      setcoworkingTypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Cities = async (countryId) => {
    try {
      setcities(null);
      const response = await dispatch(getCountryCity(countryId)).unwrap();
      setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Areas = async (cityId) => {
    try {
      setareas(null);
      const response = await dispatch(getCityArea(cityId)).unwrap();
      setareas(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (country) {
      Cities(country?.id);
    }
  }, [country]);

  useEffect(() => {
    if (mycities) {
      Areas(mycities);
    }
  }, [mycities]);

  const Type = async () => {
    try {
      setTypes(null);
      const response = await dispatch(getRoomDeskTypes()).unwrap();
      setTypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const EditLocation = async () => {
    try {
      setload(true)
      seteditLocation(null);
      const response = await dispatch(EditSpaceDetail(spaceId)).unwrap();
      seteditLocation(response?.data[0]);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    if (spaceId !== "add-space") {
      EditLocation();
    }
  }, [spaceId]);

  const handleImageUpload = (newImages) => {
    setImages(newImages);
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleCheckboxChange = (text) => {
    const updatedCheckboxes = operationDays.includes(text)
      ? operationDays.filter((item) => item !== text)
      : [...operationDays, text];
    setdaysType("")
    setoperationDays(updatedCheckboxes);
  };

  const handleGroupSelection = (e) => {
    const selectedValue = e.target.value;
    let updatedDays = [];
    if (selectedValue === 'weekday') {
      updatedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
      setdaysType("weekday")
    } else if (selectedValue === 'full') {
      updatedDays = [...daysOfWeek];
      setdaysType("full")
    }
    setoperationDays(updatedDays);
  };

  const handleCheckboxChangeTour = (text) => {
    const updatedCheckboxes = operationDaysTour.includes(text)
      ? operationDaysTour.filter((item) => item !== text)
      : [...operationDaysTour, text];
    setdaysTypeTour("")
    setoperationDaysTour(updatedCheckboxes);
  };

  const handleGroupSelectionTour = (e) => {
    const selectedValue = e.target.value;
    let updatedDays = [];
    if (selectedValue === 'weekday') {
      updatedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
      setdaysTypeTour("weekday")
    } else if (selectedValue === 'full') {
      updatedDays = [...daysOfWeek];
      setdaysTypeTour("full")
    }
    setoperationDaysTour(updatedDays);
  };

  const timeOptions = [
    { id: '24-hours', label: '24 Hours', start: '00:00', end: '23:59' },
    { id: '8-to-5-hours', label: '8 to 5', start: '08:00', end: '17:00' },
    { id: '8-to-6-hours', label: '8 to 6', start: '08:00', end: '18:00' },
    { id: '9-to-5-hours', label: '9 to 5', start: '09:00', end: '17:00' },
    { id: '9-to-6-hours', label: '9 to 6', start: '09:00', end: '18:00' },
    { id: '9-to-8-hours', label: '9 to 8', start: '09:00', end: '20:00' },
    { id: '9-to-9-hours', label: '9 to 9', start: '09:00', end: '21:00' },
    { id: 'none', label: 'None', start: '', end: '' },
  ];

  const handleTimeSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = timeOptions.find(option => option.id === selectedValue);
    if (selectedOption) {
      setspaceStartTime(selectedOption.start);
      setspaceEndTime(selectedOption.end);
      setis24Hours(selectedOption.id);
      setisTimeDisabled(selectedOption.id !== 'none');
    }
  };

  const handleTimeSelectChangeTour = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = timeOptions.find(option => option.id === selectedValue);
    if (selectedOption) {
      setspaceStartTimeTour(selectedOption.start);
      setspaceEndTimeTour(selectedOption.end);
      setis24HoursTour(selectedOption.id);
      setisTimeDisabledTour(selectedOption.id !== 'none');
    }
  };


  const handleCheckboxChange2 = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedAmenities(prevSelectedAmenities => [...prevSelectedAmenities, id]);
    } else {
      setSelectedAmenities(prevSelectedAmenities => prevSelectedAmenities.filter(item => item !== id));
    }
  };

  const handleCheckboxChange3 = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedTypes(prevSelectedTypes => [...prevSelectedTypes, id]);
    } else {
      setSelectedTypes(prevSelectedTypes => prevSelectedTypes.filter(item => item !== id));
    }
  };

  const Features = async () => {
    try {
      setload(true);
      setfeatures(null);
      const response = await dispatch(getFeatures()).unwrap();
      setfeatures(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Countries();
    CoworkingType();
    Features();
    Type();
  }, []);

  const social = { "faceBook": facebook, "twitter": twitter, "instagram": instagram, "youtube": youtube, "tiktok": tiktok, "linkedIn": linkedin }

  const saveBranch = async (e) => {
    e.preventDefault();
    try {
      setload(true);
      if (email) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }
      if (!editLocation) {
        if (!businessname) {
          toast.error('Business Name is required');
          setload(false);
          return;
        }
        if (!type) {
          toast.error('Business Type is required');
          setload(false);
          return;
        }
      }
      if (!name) {
        toast.error('Space Name is required');
        setload(false);
        return;
      }
      if (!spaceStartTime) {
        toast.error('Space Start Time is required');
        setload(false);
        return;
      }
      if (!spaceEndTime) {
        toast.error('Space End Time is required');
        setload(false);
        return;
      }
      if (operationDays.length === 0) {
        toast.error('Operation Days is required');
        setload(false);
        return;
      }
      if (spaceEndTimeTour && spaceStartTimeTour) {
        const startTime = spaceStartTimeTour;
        const endTime = spaceEndTimeTour;

        const start = new Date(`1970-01-01T${startTime}`);
        const end = new Date(`1970-01-01T${endTime}`);

        if (end < start) {
          toast.error("Tour End Time cannot be before Tour Start Time.");
          setload(false);
          return;
        }
      }
      if (!address) {
        toast.error('Address is required');
        setload(false);
        return;
      }
      if (!mycities) {
        toast.error('City is required');
        setload(false);
        return;
      }
      if (!country) {
        toast.error('Country is required');
        setload(false);
        return;
      }
      if (!area) {
        toast.error('Area is required');
        setload(false);
        return;
      }
      if (!phone) {
        toast.error('Phone is required');
        setload(false);
        return;
      }
      if (!selectedAmenities?.length == 1) {
        toast.error('Ameneties/Facilities is required');
        setload(false);
        return;
      }
      if (selectedTypes?.length === 0) {
        toast.error('Types is required');
        setload(false);
        return;
      }
      const data = new FormData();
      if (!editLocation) {
        if (path?._id) {
          data.append("businessId", path?._id);
        } else {
          data.append("businessName", businessname);
          data.append("businessType", type);
          data.append("currency", currency);
        }
      } else {
        data.append("spaceId", spaceId);
      }
      data.append("description", overview);
      data.append("name", name);
      data.append("slug", slug);
      data.append("spaceStartTime", spaceStartTime);
      data.append("spaceEndTime", spaceEndTime);
      data.append("days", JSON.stringify(operationDays));
      data.append("tourStartTime", spaceStartTimeTour);
      data.append("tourEndTime", spaceEndTimeTour);
      data.append("tourDays", JSON.stringify(operationDaysTour));
      data.append("types", JSON.stringify(selectedTypes));
      data.append("facilities", JSON.stringify(selectedAmenities));
      data.append("social", JSON.stringify(social));
      data.append("address", address);
      data.append("lat", lat);
      data.append("long", long);
      data.append("spaceManagerName", contact_person);
      data.append("spacePhone", phone);
      data.append("spaceEmail", email);
      data.append("spaceMobile", whatsapp);
      data.append("website", website);
      data.append("postCode", postCode);
      data.append("country", country?._id);
      data.append("city", mycities);
      data.append("area", area);
      data.append("featureIndex", dp);
      data.append("prevImages", JSON.stringify(prev_images));
      data.append("singleLocation", singleLocation);
      images.forEach((item) => {
        data.append("spaceImage", item);
      });
      console.log('Form Data:', Object.fromEntries(data.entries()));
      if (editLocation) {
        await dispatch(editSpace(data)).unwrap();
      } else {
        await dispatch(addSpace(data)).unwrap();
      }
      setload(false);
      navigate('/spaces')
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    setcountry(countries?.find(user => user.name === geocountry) || '');
    setSearchValue(countries?.find(user => user.name === geocountry)?.name || '');
    setpostCode(geopostcode || '');
    setaddress(location || "");
  }, [geocountry, geocity, geoarea, geopostcode]);

  useEffect(() => {
    if (geocity) {
      const FindCity = cities?.find(user => user.name === geocity)
      setmycities(FindCity?._id)
      setSearchValue1(FindCity?.name)
      setcityCountry(FindCity)
    }
  }, [country, cities]);

  useEffect(() => {
    if (singleLocation) {
      setname(businessname)
    } else if (editLocation) {
      setname(editLocation?.name)
    } else {
      setname("")
    }
  }, [singleLocation, businessname, editLocation]);


  useEffect(() => {
    if (name) {
      const slugifiedName = generateSlug(name);
      setslug(slugifiedName);
    }
  }, [name]);

  useEffect(() => {
    if (country) {
      const findCurrency = countries?.find(user => user._id === country?._id)?.currency;
      setcurrency(findCurrency);
    }
  }, [country]);

  useEffect(() => {
    if (images && images.length > 0) {
      setdp(0);
    }
  }, [images]);

  useEffect(() => {
    if (path) {
      setbusinessname(path.name);
      settype(path.type);
      setsingleLocation(false)
    }
  }, [path]);


  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const handlePhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setphone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setphone(formattedPhone);
  };





  useEffect(() => {
    if (editLocation) {
      setbusinessname(editLocation?.businesses?.name);
      settype(editLocation?.businesses?.type);
      setname(editLocation?.name);
      setslug(editLocation?.slug);
      setoperationDays(editLocation?.days)
      setspaceStartTime(editLocation?.spaceStartTime)
      setspaceEndTime(editLocation?.spaceEndTime)
      setoperationDaysTour(editLocation?.tourDays || [])
      setspaceStartTimeTour(editLocation?.tourStartTime || "")
      setspaceEndTimeTour(editLocation?.tourEndTime || "")
      setoverview(editLocation?.description)
      setaddress(editLocation?.location?.address)
      setarea(editLocation?.areas?._id)
      setSearchValue2(editLocation?.areas?.name)
      setmycities(editLocation?.cities?._id)
      setSearchValue1(editLocation?.cities?.name)
      setcityCountry(editLocation?.cities)
      setcountry(editLocation?.countries)
      setSearchValue(editLocation?.countries?.name)
      setpostCode(editLocation?.location?.postCode)
      setcontact_person(editLocation?.spaceManagerName)
      setwebsite(editLocation?.website)
      setemail(editLocation?.spaceEmail)
      setphone(editLocation?.spacePhone)
      setfacebook(editLocation?.social?.faceBook)
      settwitter(editLocation?.social?.twitter)
      setlinkedin(editLocation?.social?.linkedIn)
      setinstagram(editLocation?.social?.instagram)
      setyoutube(editLocation?.social?.youtube)
      settiktok(editLocation?.social?.tiktok)
      setSelectedAmenities(editLocation?.facilities)
      setSelectedTypes(editLocation?.types)
      setprev_images(editLocation?.image)
      setdp(editLocation?.featureImage)
      setsingleLocation(false)
    }
  }, [editLocation]);



  useEffect(() => {
    if (editLocation?.featureImage) {
      const foundIndex = prev_images?.findIndex(image => image === editLocation?.featureImage);
      if (foundIndex !== -1) {
        setdp(foundIndex);
      }
    }
  }, [editLocation, prev_images]);

  useEffect(() => {
    if (editLocation) {
      document.title = editLocation?.name
    }
  }, [editLocation, location.pathname]);


  const [searchValue, setSearchValue] = useState('');
  const [searchValue1, setSearchValue1] = useState('');

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [searchValue2, setSearchValue2] = useState('');
  const [highlightedIndex2, setHighlightedIndex2] = useState(-1);


  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setSearchValue2(value);
    if (value.length > 0) {
      setShowDropdown2(true);
    } else {
      setShowDropdown2(false);
    }
  };
  const handleOptionClick2 = (data) => {
    setSearchValue2(data?.name);
    setarea(data?._id);
    setShowDropdown2(false);
    setShowAdd(true)
  };

  const filteredAreas = areas?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue2?.toLowerCase())
  );

  const handleKeyDown2 = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex2((prevIndex) =>
        prevIndex < filteredAreas.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex2((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex2 !== -1) {
        handleOptionClick2(filteredAreas[highlightedIndex2]);
      }
    }
  };

  useEffect(() => {
    const fetchLatLong = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyDq47rN8ypjHfiSdhcm9uG4ADKS_Vsvrug`
        );
        const data = await response.json();

        if (data.status === 'OK') {
          const location = data.results[0].geometry.location;
          setlat(location.lat);
          setlong(location.lng);
        } else {
          console.error('Geocoding API error:', data.status);
        }
      } catch (error) {
        console.error('Error fetching geocoding data:', error);
      }
    };

    if (address && lat === 0 && long === 0) {
      fetchLatLong();
    }
  }, [address]);


  useEffect(() => {
    if (country?.name !== cityCountry?.country_name) {
      setcityCountry("");
      setSearchValue1("");
      setmycities("");
      setarea("");
      setSearchValue2("")
    } else {

    }
  }, [country, cityCountry]);

  useEffect(() => {
    if (!editLocation && coworkingTypes) {
      const CoworkingName = "Coworking Office";
      const matchingCoworkingType = coworkingTypes?.find(type => type?.name === CoworkingName)?._id;
      settype(matchingCoworkingType);
    }
  }, [coworkingTypes]);

  const [compulsary, setcompulsary] = useState(false);

  useEffect(() => {
    if (compulsary) {
      const typesArray = ["Dedicated Desks", "Meeting Room", "Private Office"]
      const featureArray = ["Collaboration areas", "Lounge areas", "Ergonomic chairs", "Standing desks", "Wi-Fi", "Printing", "Scanning", "Charging stations", "Tech support services", "Cleaning services", "Air conditioning", "On-site cafe or kitchenette", "Receptionist or front desk services"]

      const selectedTypeIds = typesArray?.map(typeName => {
        const matchingType = Types?.find(type => type?.name === typeName)
        return matchingType ? matchingType._id : null
      })

      const selectedFeatureIds = featureArray.map(featureName => {
        const matchingFeature = features?.find(feature => feature?.name === featureName)
        return matchingFeature ? matchingFeature._id : null
      })

      setSelectedTypes(selectedTypeIds)
      setSelectedAmenities(selectedFeatureIds)
    }
  }, [compulsary]);

  const addArea = async () => {
    try {
      const payload = {
        name: searchValue2,
        cityId: mycities,
      }
      const response = await dispatch(addCityArea(payload)).unwrap();
      setSearchValue2(response?.data?.name)
      setarea(response?.data?._id)
      setShowDropdown2(false);
      setShowAdd(true)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleCountrySelect = (country) => {
    setSearchValue(country?.name);
    setcountry(country);
    console.log('Selected Country:', country);
  };

  const handleCitySelect = (city) => {
    setcityCountry(city)
    setSearchValue1(city?.name);
    setmycities(city?._id);
    console.log('Selected city:', city);
  };

  const profile = useSelector(getProfile)
  const pageTitle = "Spaces";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      {load && <Spinner />}
      <ContentSection title={editLocation ? "Edit Space" : "Add Space"}>
        <Breadcrumb items={[{ label: 'Menu', }, { label: editLocation ? "Edit Space" : "Add Space", active: true },]} />
        <Title bottomSpace={2}>{editLocation ? "Edit Space" : "Add Space"}</Title>
        <BoxSection bottomSpace={4}>
          <div className="row gy-3 mb-3">
            <div className="col-md-12">
              <FloatingInput value={businessname} onChange={(e) => setbusinessname(e.target.value)} disabled={path || editLocation} placeholder="Business Name" autoComplete="business name" />
            </div>
            <div className={`col-md-12 ${!path?.type ? "" : "d-none"} ${editLocation ? "d-none" : ""}`}>
              <p class="font-size-16 fw-semibold black-color mb-2">Locations</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <SelectButton name="locations-multiple" onChange={() => setsingleLocation(true)} checked={singleLocation === true} id="single-location" label="Single Location" />
                <SelectButton name="locations-multiple" onChange={() => setsingleLocation(false)} checked={singleLocation === false} id="Multiple-location" label="Multiple Location" />
              </div>
            </div>
            {!singleLocation && <div className="col-md-12">
              <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Location Name" autoComplete="location name" />
            </div>}

            <div className="col-md-12">
              <p class="font-size-16 fw-semibold black-color mb-2">Opening Timings</p>
              <div className="row">
                <div className="col-md-5">
                  <div className="d-flex flex-wrap gap-2 mb-3">
                    {daysOfWeek.map((data) => (
                      <SelectButton name="opening-days" checked={operationDays?.includes(data)} onChange={() => handleCheckboxChange(data)} type="checkbox" id={`${data}-days`} value={data} label={data} />
                    ))}
                    <SelectButton name="opening-days" checked={daysType === "weekday"} onChange={handleGroupSelection} type="checkbox" id="weekday" label="Weekday" value="weekday" />
                    <SelectButton name="opening-days" checked={daysType === "full"} onChange={handleGroupSelection} type="checkbox" id="fullweek" label="Full Week" value="full" />
                  </div>
                </div>
                <div className="col-md-3 d-flex gap-1">
                  <FloatingInput type="time" value={spaceStartTime} onChange={(e) => setspaceStartTime(e.target.value)} disabled={isTimeDisabled} placeholder="Start Time" className="flex-grow-1" />
                  <FloatingInput type="time" value={spaceEndTime} onChange={(e) => setspaceEndTime(e.target.value)} disabled={isTimeDisabled} placeholder="End Time" className="flex-grow-1" />
                </div>
                <div className="col-md-4 align-self-center">
                  {timeOptions?.map(option => (
                    <div key={option.id} className="form-check form-check-inline cw-checkbox">
                      <input className="form-check-input" type="radio" name="time-select" id={option.id} checked={is24Hours === option.id} onChange={handleTimeSelectChange} value={option.id} />
                      <label className="form-check-label" htmlFor={option.id}>{option.label}</label>
                    </div>
                  ))}
                </div>

              </div>
            </div>
            <div className="col-md-12">
              <p class="font-size-16 fw-semibold black-color mb-2">Book a Tour Timings</p>
              <div className="row">
                <div className="col-md-5">
                  <div className="d-flex flex-wrap gap-2 mb-3">
                    {daysOfWeek.map((data) => (
                      <SelectButton name="opening-days-tour" checked={operationDaysTour?.includes(data)} onChange={() => handleCheckboxChangeTour(data)} type="checkbox" id={`${data}-tour`} value={data} label={data} />
                    ))}
                    <SelectButton name="opening-days-tour" checked={daysTypeTour === "weekday"} onChange={handleGroupSelectionTour} type="checkbox" id="weekday-tour" label="Weekday" value="weekday" />
                    <SelectButton name="opening-days-tour" checked={daysTypeTour === "full"} onChange={handleGroupSelectionTour} type="checkbox" id="fullweek-tour" label="Full Week" value="full" />
                  </div>
                </div>
                <div className="col-md-7 d-flex gap-1">
                  <FloatingInput type="time" value={spaceStartTimeTour} onChange={(e) => setspaceStartTimeTour(e.target.value)} disabled={isTimeDisabledTour} placeholder="Start Time Tour" className="flex-grow-1" />
                  <FloatingInput type="time" value={spaceEndTimeTour} onChange={(e) => setspaceEndTimeTour(e.target.value)} disabled={isTimeDisabledTour} placeholder="End Time Tour" className="flex-grow-1" />
                </div>

              </div>
            </div>
            <div className="col-md-12">
              <FloatingTextArea value={overview} onChange={(e) => setoverview(e.target.value.replace(/\r?\n/g, '<br>'))} placeholder="Overview" height="200" />
            </div>
          </div>

          <p class="font-size-16 black-color fw-semibold mb-2">Address</p>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="cw-shadow rounded  mb-3">
                <GooglePlacesAutocomplete apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}
                  selectProps={{ value, onChange: setvalue, placeholder: editLocation ? address : "Enter your location", }} />
              </div>
              <FloatingTextArea value={address} onChange={(e) => setaddress(e.target.value)} placeholder="Address" bottomSpace={3} />
            </div>
            <div className="col-md-6">
              <DropdownSearch items={countries} label="Country" placeholder="Enter country" onItemSelect={handleCountrySelect} bottomSpace={3} valueName={searchValue} />
              {country && (
                <DropdownSearch items={cities} label="City" placeholder="Enter city" onItemSelect={handleCitySelect} bottomSpace={3} valueName={searchValue1} />
              )}
              {mycities && (
                <div className="mb-3">
                  <div className="d-flex gap-1">
                    <div class="form-floating cw-input flex-grow-1">
                      <input type="text" class="form-control" onKeyDown={handleKeyDown2} maxLength={120} onChange={(e) => handleInputChange2(e)} value={searchValue2} id="area" placeholder="area" autoComplete="off" />
                      <label for="area">Area</label>
                    </div>
                    {!showAdd && !area && filteredAreas?.length === 0 && (
                      <button className="btn cw-btn cw-yellow-btn" onClick={addArea}>Add</button>
                    )}
                  </div>
                  {showDropdown2 && (
                    <div className="dropdown cw-select-dropdown">
                      <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {areas?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.map((data, index) => (
                          <li key={index} onClick={() => handleOptionClick2(data)} className={highlightedIndex2 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {filteredAreas === 0 && (
                          <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              <FloatingInput value={postCode} onChange={(e) => setpostCode(e.target.value)} placeholder="Postcode" maxLength={9} autoComplete="Postcode" />
            </div>

          </div>

          <p class="font-size-16 black-color fw-semibold mb-2">Contact Details</p>
          <div className="row gy-3 mb-3">
            <div className="col-md-3">
              <FloatingInput value={contact_person} onChange={(e) => setcontact_person(e.target.value)} placeholder="Contact Person" maxLength={40} autoComplete="name" />
            </div>
            <div className="col-md-3">
              <FloatingInput type="url" value={website} onChange={(e) => setwebsite(e.target.value)} placeholder="Website" autoComplete="website" />
            </div>
            <div className="col-md-3">
              <FloatingInput type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email" maxLength={320} autoComplete="email" />
            </div>
            <div className="col-md-3 d-flex gap-2 align-items-center">
              <FloatingInput type="tel" value={phone} onChange={handlePhoneChange} placeholder="Phone" className="flex-grow-1" autoComplete="phone" />
              <div class="form-check form-check-inline cw-checkbox">
                <input onChange={(e) => setwhatsapp(e.target.checked ? phone : "")} class="form-check-input" type="checkbox" id="whatsapp" value="whatsapp" />
                <label class="form-check-label" for="whatsapp">Whatsapp</label>
              </div>
            </div>

          </div>

          <p class="font-size-16 black-color fw-semibold mb-2">Amenities / Facilities</p>
          {!editLocation && <div className="mb-3">
            <div className="form-check form-check-inline cw-checkbox">
              <input className="form-check-input" type="checkbox" name="check-compusary" id="select-compusary-fields" checked={compulsary} onChange={() => setcompulsary(!compulsary)} />
              <label className="form-check-label" htmlFor="select-compusary-fields">Select Compulsary</label>
            </div>
          </div>}
          <p className="font-size-16 fw-semibold black-color mb-2">Facilities</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
            {Types?.map((data, i) => (
              <SelectButton key={i} name="roomDesk-types" type="checkbox" value={data?._id} checked={selectedTypes?.includes(data?._id)} onChange={handleCheckboxChange3} id={data?._id} label={data?.name} />
            ))}
          </div>

          {[...new Set(features?.map(option => option?.featureCategoryId?.name))]?.map(category => (
            <>
              <p className="font-size-16 fw-semibold black-color mb-2">{category}</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {features?.filter(option => option.featureCategoryId?.name === category)?.map(option => (
                  <SelectButton key={option?.id} name="ameneties" type="checkbox" value={option?._id} checked={selectedAmenities?.includes(option?._id)} onChange={handleCheckboxChange2} id={option?._id} label={option?.name} />
                ))}
              </div>
            </>
          ))}


          <p class="font-size-16 black-color fw-semibold mb-2">Social Network</p>
          <div className="row gy-3 mb-3">
            <div className="col-md-4">
              <FloatingInput value={facebook} onChange={(e) => setfacebook(e.target.value)} placeholder="Facebook" autoComplete="facebook" />
            </div>
            <div className="col-md-4">
              <FloatingInput value={twitter} onChange={(e) => settwitter(e.target.value)} placeholder="Twitter/X" autoComplete="twitter" />
            </div>
            <div className="col-md-4">
              <FloatingInput value={linkedin} onChange={(e) => setlinkedin(e.target.value)} placeholder="LinkedIn" autoComplete="linkedin" />
            </div>
            <div className="col-md-4">
              <FloatingInput value={instagram} onChange={(e) => setinstagram(e.target.value)} placeholder="Instagram" autoComplete="instagram" />
            </div>
            <div className="col-md-4">
              <FloatingInput value={youtube} onChange={(e) => setyoutube(e.target.value)} placeholder="Youtube" autoComplete="youtube" />
            </div>
            <div className="col-md-4">
              <FloatingInput value={tiktok} onChange={(e) => settiktok(e.target.value)} placeholder="Tiktok" autoComplete="tiktok" />
            </div>
          </div>


          <p class="font-size-16 black-color fw-semibold mb-2">Photos & Videos</p>
          <ImageUpload prevImages={prev_images} images={images} onImageUpload={handleImageUpload} onImageRemovePrev={handleImageRemovePrev} onImageRemove={handleImageRemove} featuredImage={dp} setFeaturedImage={setdp} />
        </BoxSection>

        <div class="d-flex justify-content-center gap-2">
          <Button onClick={() => navigate("/spaces")} variant="black-outline" size="small">Cancel</Button>
          <Button onClick={saveBranch} size="small" disabled={load || !permission?.write}>Submit</Button>
        </div>

      </ContentSection>
    </>
  )
}

export default AddLocation