import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import { getBookedTours, getProfile } from "../../store/apiSlice";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import { getPagePermission } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";

const BookedTours = () => {
    const profile = useSelector(getProfile)
    const dispatch = useDispatch();
    const [bookedToursList, setbookedToursList] = useState(null);
    const [load, setload] = useState(false);


    const BookedFetch = async () => {
        try {
            setload(true);
            setbookedToursList(null);
            const response = await dispatch(getBookedTours()).unwrap();
            setbookedToursList(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        BookedFetch();
    }, []);


    const navigate = useNavigate();
    const pageTitle = "Booked Tours";
    const permission = getPagePermission(pageTitle, profile);
    console.log("Permission:", permission);

    useEffect(() => {
        if (permission && permission.read === false) {
            navigate("/");
        }
    }, [permission, navigate]);

    return (
        <>
            <ContentSection title="Booked Tours list">
                <Breadcrumb items={[{ label: 'Menu', }, { label: 'Booked Tours list', active: true },]} />
                <Title bottomSpace={2}>Booked Tours list</Title>
                <BoxSection>
                    <p className="font-size-20 pink-color">Booked Tours list : {bookedToursList?.length}</p>
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Space Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : bookedToursList?.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.userId?.name}</td>
                                    <td>{item?.userId?.email}</td>
                                    <td>{item?.userId?.phone}</td>
                                    <td>{item?.spaceId?.name}</td>
                                    <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                                    <td>{item?.startTime}</td>
                                    <td>{item?.endTime}</td>
                                </tr>))}
                        </tbody>
                    </Table>
                </BoxSection>
            </ContentSection>
        </>
    )
}

export default BookedTours