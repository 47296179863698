import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from "axios";
import { extraReducers } from "./apiReducer"

axios.defaults.baseURL = process.env.REACT_APP_APIURL
const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
axios.defaults.headers.common['Authorization'] = user?.token;

const initialState = {
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    user: user,
}

export const signinAdmin = createAsyncThunk('/admin/login', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/login`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const signOut = createAsyncThunk("/admin/sign-out", async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/sign-out`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addAdmin = createAsyncThunk('/admin/add-sub-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-sub-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editAdmin = createAsyncThunk('/admin/edit-sub-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-sub-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteAdmin = createAsyncThunk('/admin/delete-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllAdmins = createAsyncThunk('/admin/list-of-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getPaymentCycle = createAsyncThunk('/admin/get-payment-cycle', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-payment-cycle`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllCountries = createAsyncThunk('/admin/list-of-country', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-country`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const EditCountry = createAsyncThunk('/admin/edit-country', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-country`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const DeleteCountry = createAsyncThunk('/admin/delete-country/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-country/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getFeatureCategories = createAsyncThunk('/admin/list-of-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addFeatureCategory = createAsyncThunk('/admin/add-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editFeatureCategory = createAsyncThunk('/admin/edit-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteFeatureCategory = createAsyncThunk('/admin/delete-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getFeatures = createAsyncThunk('/admin/list-of-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addFeature = createAsyncThunk('/admin/add-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editFeature = createAsyncThunk('/admin/edit-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteFeature = createAsyncThunk('/admin/delete-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteQuotation = createAsyncThunk('/admin/delete-requested-quote', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-requested-quote?id=${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getDeskTypes = createAsyncThunk('/admin/list-of-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-room-desk-type?type=${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getRoomTypes = createAsyncThunk('/admin/list-of-room-desk-type?type=Room', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-room-desk-type?type=Room`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getRoomDeskTypes = createAsyncThunk('/admin/get-both-room-and-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-both-room-and-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addDeskRoomType = createAsyncThunk('/admin/add-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-room-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editDeskRoomType = createAsyncThunk('/admin/edit-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-room-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteDeskRoomType = createAsyncThunk('/admin/delete-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-room-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getCountryCity = createAsyncThunk('/admin/list-of-country-for-dropdown', async (countryId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-country-for-dropdown/${countryId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCityArea = createAsyncThunk('/admin/list-of-area', async (cityId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-area?cityId=${cityId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCityArea2 = createAsyncThunk('/admin/list-of-area-2', async ({ cityId, search, page, pageSize = 50 }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-area-2?cityId=${cityId}&search=${search}&page=${page}&pageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addCityArea = createAsyncThunk('/admin/add-area', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-area`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editCityArea = createAsyncThunk('/admin/edit-area', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-area`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteCityArea = createAsyncThunk('/admin/delete-area', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-area`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCountryCitySearch = createAsyncThunk('/admin/list-of-country-for-dropdown-admin', async ({ countryId, page, search }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-country-for-dropdown-admin?countryId=${countryId}&page=${page}&search=${search}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCity = createAsyncThunk('/admin/list-of-city', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-city`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCity = createAsyncThunk('/admin/add-city', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-city`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editCity = createAsyncThunk('/admin/edit-city', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-city`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteCity = createAsyncThunk('/admin/delete-city', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-city/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addSpace = createAsyncThunk('/admin/add-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editSpace = createAsyncThunk('/admin/edit-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const SpaceDetail = createAsyncThunk('/admin/space-detail', async (spaceId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/space-detail?id=${spaceId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const EditSpaceDetail = createAsyncThunk('/admin/space-detail-for-edit', async (spaceId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/space-detail-for-edit?id=${spaceId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addReviewSpace = createAsyncThunk('/admin/add-review', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-review`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editReviewSpace = createAsyncThunk('/admin/edit-review', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-review`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getSpaceReviews = createAsyncThunk('/admin/list-of-reviews', async (spaceId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-reviews?spaceId=${spaceId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteReviews = createAsyncThunk('/admin/delete-review', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-review/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteSpace = createAsyncThunk('/admin/delete-space', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-space/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllSpaces = createAsyncThunk('/admin/list-of-space', async ({ city, country, adminId, page, search, isTrending, isFeatured, date, emailSearch }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-space?city=${city}&country=${country}&adminId=${adminId}&search=${search}&page=${page}&isTrending=${isTrending}&isFeatured=${isFeatured}&date=${date}&emailSearch=${emailSearch}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
// export const getAllSpacesByBusinesses = createAsyncThunk('/admin/list-of-space-created-by-owner', async ({ city, country, adminId, page, search, isTrending, isFeatured, date }, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`/admin/list-of-space-created-by-owner?city=${city}&country=${country}&adminId=${adminId}&search=${search}&page=${page}&isTrending=${isTrending}&isFeatured=${isFeatured}&date=${date}`)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })
export const getAllSpacesByBusinesses = createAsyncThunk('/admin/list-of-space-created-by-owner', async ({ city, country, adminId, page, search, isTrending, isFeatured, date, isContract }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-space-created-by-owner`, {
            params: {
                ...(city && { city }),
                ...(country && { country }),
                ...(adminId && { adminId }),
                ...(page && { page }),
                ...(search && { search }),
                ...(isTrending !== undefined && { isTrending }),
                ...(isFeatured !== undefined && { isFeatured }),
                ...(date && { date }),
                ...(isContract !== undefined && { isContract })
            }
        });

        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});
export const getSpacesByBusiness = createAsyncThunk('/admin/list-of-spaces-by-business', async ({ city, businessId, page, search = "", }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-spaces-by-business?city=${city}&&businessId=${businessId}&search=${search}&page=${page}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBlogCategory = createAsyncThunk('/admin/list-of-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getContactUsList = createAsyncThunk('/admin/contact-us', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/contact-us`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addBlogCategory = createAsyncThunk('/admin/add-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editBlogCategory = createAsyncThunk('/admin/edit-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteBlogCategory = createAsyncThunk('/admin/delete-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBlogs = createAsyncThunk('/admin/list-of-blogs', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-blogs`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addBlogs = createAsyncThunk('/admin/add-blog', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-blog`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editBlogs = createAsyncThunk('/admin/edit-blog', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-blog`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteBlogs = createAsyncThunk('/admin/delete-blog', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-blog/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getInclusions = createAsyncThunk('/admin/list-of-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addInclusions = createAsyncThunk('/admin/add-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editInclusions = createAsyncThunk('/admin/edit-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteInclusions = createAsyncThunk('/admin/delete-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getCoworkingTypes = createAsyncThunk('/admin/get-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCoworkingTypes = createAsyncThunk('/admin/add-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editCoworkingTypes = createAsyncThunk('/admin/edit-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteCoworkingTypes = createAsyncThunk('/admin/delete-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addSpaceType = createAsyncThunk('/admin/add-room-and-desk', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-room-and-desk`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editSpaceTypee = createAsyncThunk('/admin/edit-room-and-desk', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-room-and-desk`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const SpaceTypeDetail = createAsyncThunk('/admin/room-desk-detail', async ({ spaceTypeId, behaveParam }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/room-desk-detail?id=${spaceTypeId}&behave=${behaveParam}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addPackages = createAsyncThunk('/admin/add-pricing-package', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-pricing-package`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editPackages = createAsyncThunk('/admin/edit-pricing-package', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-pricing-package`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getPackages = createAsyncThunk('/admin/list-of-pricing-packages', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-pricing-packages`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getRequestedQuotes = createAsyncThunk('/admin/list-of-requested-quotes', async ({ page, limit, dataType, search }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-requested-quotes?dataType=${dataType}&page=${page}&limit=${limit}&search=${search}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getCareers = createAsyncThunk('/admin/list-of-career', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-career`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getClaimSpaces = createAsyncThunk('/admin/get-claim-space', async ({ page, limit, dataType, search, isResolved }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-claim-space?dataType=${dataType}&page=${page}&limit=${limit}&search=${search}&isResolved=${isResolved}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const manuallyResolveClaim = createAsyncThunk('/admin/resolve-claim-maunally', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/resolve-claim-maunally?claimId=${bodyData}`,)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getNewsletterEmails = createAsyncThunk('/admin/list-of-newsletter', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-newsletter`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getEnterpriseSoltions = createAsyncThunk('/admin/list-of-enterprices', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-enterprices`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getReportSpaces = createAsyncThunk('/admin/get-report-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-report-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getLandlordProperty = createAsyncThunk('/admin/get-all-property', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-all-property`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAbandonedSpaces = createAsyncThunk('/admin/list-of-abundant-space', async ({ page, search }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-abundant-space?page=${page}&search=${search}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessCountries = createAsyncThunk('/admin/get-business-for-country', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-business-for-country?id=${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessCountrySpaces = createAsyncThunk('/admin/get-spaces-by-country-and-business', async ({ businessId, countryId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-spaces-by-country-and-business?businessId=${businessId}&countryId=${countryId}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessess = createAsyncThunk('/admin/get-all-business', async ({ search, isAdmin, limit, page, searchEmail }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-all-business?isAdmin=${isAdmin}&search=${search}&searchEmail=${searchEmail}&page=${page}&limit=${limit}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessessByContract = createAsyncThunk('/admin/contracted-business', async ({ search, isAdmin, limit, page, searchEmail }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/contracted-business`, {
            params: { ...(isAdmin && { isAdmin }), ...(search && { search }), ...(searchEmail && { searchEmail }), ...(page && { page }), ...(limit && { limit }) }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
}
);
export const sendClaimSpaceEmail = createAsyncThunk('/admin/promotional-email-spaces', async ({ cityId, countryId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/promotional-email-spaces?countryId=${countryId}&cityId=${cityId}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const aproveBusiness = createAsyncThunk('/admin/approve-business', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/approve-business/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const verifiedBusiness = createAsyncThunk('/admin/verify-business', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/verify-business/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const FeaturedSpace = createAsyncThunk('/admin/feature-space', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/feature-space/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const Trending2Space = createAsyncThunk('/admin/trending-space', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/trending-space/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const InviteBusinessOwner = createAsyncThunk("/admin/invite-business-owner", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/invite-business-owner`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const alreadyUserBuisinessAssign = createAsyncThunk("/admin/add-space-to-existing-business", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-space-to-existing-business`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addContractBusiness = createAsyncThunk("/admin/add-business-contract", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-business-contract`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const changeBusinessName = createAsyncThunk("/admin/add-business-by-country", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-business-by-country`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const ResendInviteBusinessOwner = createAsyncThunk("/admin/resend-email-to-business-email", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/resend-email-to-business-email`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getBookedTours = createAsyncThunk("/admin/list-of-booked-tours", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-booked-tours`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const spaceClosed = createAsyncThunk("/admin/change-space-status", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/change-space-status`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const changePassword = createAsyncThunk("/admin/update-password", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/update-password`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getBookedDemos = createAsyncThunk('/admin/list-of-book-demo', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-book-demo`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getmetaDataList = createAsyncThunk('/admin/list-of-meta-data', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-meta-data`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addMetaData = createAsyncThunk("/admin/add-meta-data", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-meta-data`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editMetaData = createAsyncThunk("/admin/edit-meta-data", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-meta-data`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const chatList = createAsyncThunk("/admin/chat-list", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/chat-list`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getAllUsers = createAsyncThunk('/admin/user-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/user-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getCustomerUsers = createAsyncThunk('/admin/list-of-customers', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-customers`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const offerQuotation = createAsyncThunk('/admin/add-price-to-quotation', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-price-to-quotation`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const rejectQuotation = createAsyncThunk('/admin/decline-quotation', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/decline-quotation`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const sendTheAttachment = createAsyncThunk("/send-attachment", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/send-attachment`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const sendEmailList = createAsyncThunk("/admin/new-year-email", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/new-year-email`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getContractTemplates = createAsyncThunk("/admin/get-contract", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-contract`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addContractTemplate = createAsyncThunk("/admin/create-contract", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/create-contract`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editContractTemplate = createAsyncThunk("/admin/edit-contract", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-contract`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteContractTemplate = createAsyncThunk("/admin/delete-contract", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-contract`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getBusinessDetail = createAsyncThunk("/admin/requested-quotes-by-business", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/requested-quotes-by-business?businessId=${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getDataProfile = createAsyncThunk("/admin/get-profile", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-profile/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addSpacePrice = createAsyncThunk("/admin/add-space-pricing", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-space-pricing`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const updateSpacePrice = createAsyncThunk("/admin/update-space-pricing", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/update-space-pricing`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getPricingBySpace = createAsyncThunk("/admin/get-space-pricing", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-space-pricing?spaceId=${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        token: (state) => {
            var user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                state.user = user
            }
        }
    },
    extraReducers
})


export const getProfile = (state) => state?.users?.user;
export const getUserID = (state) => state?.users?.user?._id;
export const getUserToken = (state) => state?.users?.user?.token;
export const getUserRole = (state) => state?.users?.user?.role;
export const { token } = userSlice.actions
export default userSlice.reducer