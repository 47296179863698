import { useDispatch, useSelector } from "react-redux";
import { alreadyUserBuisinessAssign, getClaimSpaces, getProfile, InviteBusinessOwner, manuallyResolveClaim, ResendInviteBusinessOwner } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import { Eye, Trash2 } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import FloatingInput from "../../components/elements/FloatingInput";
import Pagination from "../../components/elements/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import SelectButton from "../../components/elements/SelectButton";
import Button from "../../components/elements/Button";
import { getPagePermission } from "../../utils/Helper";


const ClaimSpaces = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [claimSpaces, setclaimSpaces] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);
  const [isResolved, setisResolved] = useState(0);

  const [claimData, setclaimData] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [business, setbusiness] = useState("");
  const [businessId, setbusinessId] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [spaces, setspaces] = useState([]);


  const dataTypes = [
    { label: "New", value: "new", isResolved: 0 },
    { label: "Past", value: "past", isResolved: 0 },
    { label: "Resolved", value: "new", isResolved: 1 },
  ]

  const [dataType, setdataType] = useState("new");
  const [search, setsearch] = useState('');
  const [totalCount, settotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type === "user") {
      setUserDetail(item);
      setId(item?._id);
      setname(item?.name);
      setemail(item?.email);
      setbusinessId(item?.business?._id);
      setbusinessName(item?.business?.name);
      setspaces(item?.spaceData)
    } else if (type === "alreadyUser") {
      setUserDetail(item?.user);
      setbusiness(item?.business);
      setspaces(item?.spaces)
      setclaimData(item?.claimData)
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
    setbusinessId("")
    setbusinessName("")
    setname("")
    setemail("")
    setspaces([])
    setUserDetail("");
    setbusiness("");
    setspaces("")
    setclaimData("")
  }

  const claimSpaceDelete = async (id) => {
    try {
      // await dispatch(deleteClaimSpace(id)).unwrap();
      setIsOpen(false);
      setclaimSpaces((prevClaims) => prevClaims?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const ClaimSpace = async () => {
    try {
      setload(true);
      setclaimSpaces(null);
      const response = await dispatch(getClaimSpaces({ page: currentPage, limit: 20, dataType, search, isResolved })).unwrap();
      setclaimSpaces(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    ClaimSpace();
  }, [currentPage, search, dataType, isResolved]);


  // const data = claimSpaces?.filter(item => item?.spaces?.isAdmin === isAdmin);

  const inviteUserClaim = async (id) => {
    try {
      const payload = {
        claimId: id,
        // businessId: businessId,
        name: name,
        email: email,
        businessName: businessName,
        spaces: spaces?.map(data => data?._id),
      }
      const response = await dispatch(InviteBusinessOwner(payload)).unwrap();
      if (response?.alreadyUser === 1) {
        const userData = response?.user;
        const businessData = response?.business;
        const data = {
          user: userData,
          business: businessData,
          spaces: spaces,
          claimData: userDetail
        }
        setIsOpen(false);
        viewModal(data, "alreadyUser")
      } else {
        closeModal()
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const alreadyUserClaim = async (id) => {
    try {
      const payload = {
        claimId: id,
        businessId: business?._id,
        userId: userDetail?._id,
        spaces: spaces?.map(data => data?._id),
      }
      await dispatch(alreadyUserBuisinessAssign(payload)).unwrap();
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const manuallyResolveClaimReq = async (id) => {
    try {
      await dispatch(manuallyResolveClaim(id)).unwrap();
      setclaimSpaces((prev) => prev?.filter(data => data?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const HandleResendInvite = async (id) => {
    try {
      await dispatch(ResendInviteBusinessOwner({ id })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const pageTitle = "Claim Spaces";
  const permission = getPagePermission(pageTitle, profile);
  console.log("Permission:", permission);

  useEffect(() => {
    if (permission && permission.read === false) {
      navigate("/");
    }
  }, [permission, navigate]);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Claim Space Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.name} />
            <TextDetail label="Email" data={userDetail?.email} />
            <TextDetail label="Phone" data={userDetail?.phone} />
            <TextDetail label="Website" data={userDetail?.website} />
            <TextDetail label="Role" data={userDetail?.relationship} />
            <p className="font-size-16 fw-bold">Spaces</p>
            <ul>
              {userDetail?.spaceData?.map((data) => (
                <li>{data?.name}</li>
              ))}
            </ul>
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => claimSpaceDelete(id)} label="Claim Space" />
        ) : modalType === "user" ? (
          <ModalContent title={`Add User for ${userDetail?.business?.name}`} closeModal={closeModal} onClick={() => inviteUserClaim(id)} buttonText="Submit">
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" bottomSpace={3} />
            <FloatingInput type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email" bottomSpace={3} />
            <FloatingInput value={businessName} onChange={(e) => setbusinessName(e.target.value)} placeholder="Business Name" />
          </ModalContent>
        ) : modalType === "alreadyUser" ? (
          <ModalContent title={`User Exists`} closeModal={closeModal} onClick={() => alreadyUserClaim(claimData?._id)} buttonText="Submit">
            <p className="font-size-16">This {userDetail?.email} belongs to {business?.name}. Are you sure you want to add the following spaces to it, or would you like to change the email?</p>
            <Button type="button" size="small" variant="black" className="font-size-14" onClick={() => viewModal(claimData, "user")}>Change Email</Button>
          </ModalContent>
        ) : null}
      </CustomModal>


      <ContentSection title="Claim Spaces">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Claim Spaces', active: true },]} />
        <Title bottomSpace={2}>Claim Spaces</Title>

        <BoxSection>
          <p className="font-size-20 pink-color">Claim Spaces : {claimSpaces?.length}</p>
          <div class="d-flex gap-2 flex-wrap mb-3">
            {dataTypes?.map((data, i) => (
              <SelectButton key={i} name="dataTypes" checked={dataType === data?.value && isResolved === data?.isResolved} value={data?.value} label={data?.label} id={data?.label} onChange={(e) => {
                setdataType(data?.value);
                setisResolved(data?.isResolved);
              }} />
            ))}
          </div>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                {dataType === "new" && <th scope="col">Business Name</th>}
                {/* <th scope="col">Space Name</th> */}
                {(dataType === "new" && isResolved === 0) && <th scope="col">Invitation</th>}
                <th scope="col">Date</th>
                {(dataType === "new" && isResolved === 0) && <th scope="col">Manually</th>}
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={10}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : claimSpaces?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.email?.substring(0, 30)}</td>
                  <td>{item?.relationship}</td>
                  {dataType === "new" && <td>{item?.business?.name}</td>}
                  {/* <td>{dataType === "new" ? item?.spaceData?.map((data, index) => (<span key={data?._id}>{data?.name}{index !== item?.spaceData?.length - 1 ? ", " : ""}</span>)) : item?.spaceData?.[0]?.name}</td> */}
                  {/* {isAdmin === 1 && <td>{item?.isUser === 0 ? "Invitation Not Sent" : "Invitation Sent"}</td>} */}
                  {(dataType === "new" && isResolved === 0) && <td style={{ verticalAlign: "middle" }}>
                    {item?.isUser === 0 ? <button onClick={() => HandleResendInvite(item?.businessOwner?._id)} disabled={item?.businessOwner?.isPassword === 1 || !permission?.write} className="btn cw-btn cw-yellow-btn px-3 py-1">Resend Invite</button>
                      : item?.isResolved === 0 ? <button disabled={item?.businessOwner?.isPassword === 1 || !permission?.write} onClick={() => viewModal(item, "user")} className="btn cw-btn cw-yellow-btn px-3 py-1">Invite</button> :
                        "Claimed"}</td>}
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  {(dataType === "new" && isResolved === 0) && <td><Button disabled={!permission?.write} variant="black" size="small" className="font-size-14" onClick={() => manuallyResolveClaimReq(item?._id)}>Manually Resolve</Button></td>}
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>
                      {permission?.delete && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
          {totalCount > 20 &&
            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />}
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default ClaimSpaces